import React, {Component} from 'react';

import { withStyles } from '@material-ui/core/styles'

import TopicBoxContainer from '../../topicBoxContainer/TopicBoxContainer';

const styles = theme => ({
})

class TopicList extends Component {    	
	
	render() {
		const { list } = this.props		
		if( !list ) return false
		return <div>{ list.map( (t) => <TopicBoxContainer topic={t} key={t.id}/>) }
		</div>
	}
}

export default withStyles(styles)(TopicList);