import React from 'react';


import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
  	row:{
  		display: 'flex',
  		justifyContent: 'center',
      flexDirection: 'column',

  	},
  	buttonContainer:{
  		cursor: "pointer",
  	}
});

function SharePageColumnLabel(props) {    	
	const { classes, list, shareOptions } = props	
	
	return <div className={classes.row}>{
		list.map( e => <div className={classes.buttonContainer}>{shareOptions[e]}</div>) 
	}</div>
			
		
}

export default withStyles(styles)(SharePageColumnLabel);