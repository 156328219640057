import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';

import QuoteListContainer from '../../quote/quoteListContainer/QuoteListContainer'
import GameContainer from '../../game/gameContainer/GameContainer'
import TopicListContainer from '../../topic/topicListContainer/TopicListContainer'
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
	section:{
		marginBottom: theme.spacing.unit * 8,
	},
	title:{
		marginBottom: theme.spacing.unit * 4,
		color: theme.palette.primary.text,		
	},
})

function CoverMain(props) {    	
	const { classes, quoteList } = props

	return <div>
		<div className={classes.section}>
			<Typography className={classes.title} component="h1" variant="h4" gutterBottom >Frases para hoy</Typography>
			<QuoteListContainer quotes={quoteList} />
		</div>
		
		<div className={classes.section}>
			<Typography className={classes.title} component="h2" variant="h4" gutterBottom >¿Quien Dijo esta frase?</Typography>
			<GameContainer doneOpen={true} isDoneList={true} />
		</div>

		<Hidden xsUp>
			<div className={classes.section} >
				<TopicListContainer ids={[7,8,9]} view="row" />				
			</div>
		</Hidden>
	</div>
}

export default withStyles(styles)(CoverMain);