import React from 'react';

import { withStyles } from '@material-ui/core/styles'


import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import IconButton from '@material-ui/core/IconButton';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {  NavLink } from 'react-router-dom';

import {getTopicUrl,getTopicImgUrl} from "../../../../helpers"

const styles = theme => ({	
	icon: {
    	color: 'rgba(255, 255, 255, 0.54)',
  	},
})

function Topics(props) {    
	const { topicList, classes } = props
	if( !topicList ) return false

	const gridTopic = <GridList cellHeight={180} className={classes.gridList}>
		{topicList.map( t => {
			if( !t ) return false
			return <GridListTile key={t.id}
				component={NavLink}
				to={getTopicUrl(t)} >
				<img src={getTopicImgUrl(t)} alt={t.title} />
				<GridListTileBar
              		title={t.title}
              		actionIcon={
                		<IconButton className={classes.icon}>
                  			<ArrowRightIcon />
                		</IconButton>
              		}
            	/>
			</GridListTile>
		})}
	</GridList>

	return gridTopic

}

export default withStyles(styles)(Topics);