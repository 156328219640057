import React, {Component} from 'react';
import { Link } from 'react-router-dom'


import {Card,CardContent,CardActions} from '@material-ui/core/';


import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';

import AuthorContainer from '../../authorContainer/AuthorContainer';

const styles = theme => ({	
	card: {
		margin: theme.spacing.unit * 2,
	},
	firstAuthors: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	actions: {
    	display: 'flex',
  	},
	expand: {
    	transform: 'rotate(0deg)',
    	transition: theme.transitions.create('transform', {
      		duration: theme.transitions.duration.shortest,
    	}),
    	marginLeft: 'auto',
    	[theme.breakpoints.up('sm')]: {
      		marginRight: -8,
    	},
  	},
  	expandOpen: {
    	transform: 'rotate(180deg)',
  	},
  	authorButton: {
  		margin: theme.spacing.unit,
  		flexGrid: 1,  	
  	},
  	expandedList: {
  		display: 'flex',
  		flexWrap: 'wrap',
  	},
  	letterTitle: {
  		textTransform: 'uppercase',
  	}
})

class AuthorLetter extends Component {    
	constructor(props) {
		super(props);

		this.state = {
			expanded: false
		}
	}	
	
	handleExpandClick = () => {
    	this.setState(state => ({ expanded: !state.expanded }));
  	}

	render() {
		const {letter, list, classes } = this.props
		
		if( !letter ) return false

		const listSummary = [...list]
		const listExtra= [...list]
		
		return <Card className={classnames(classes.card, {
              			[classes.cardExpandOpen]: this.state.expanded,
            		})} >
			<CardContent>
				<Typography variant="h5" component="h2" className={classes.letterTitle}>
					{letter}
				</Typography>
				<div className={classes.firstAuthors}>
					{listSummary.sort((a,b) =>  {
						if(a.aut_url < b.aut_url) return -1
	  					if(a.aut_url > b.aut_url) return 1
	  					return 0
	  				}).splice(0,5).map( a => <AuthorContainer author={a} key={"main-" + a.aut_id} />)}
	  			</div>
			</CardContent>
			<CardActions className={classes.actions} disableActionSpacing>
				<Button
            		className={classnames(classes.expand)}
            		onClick={this.handleExpandClick}
            		aria-expanded={this.state.expanded}
            		aria-label="Mostrar más"
          		>
            		<ExpandMoreIcon className={classnames({
              			[classes.expandOpen]: this.state.expanded,
            		})} />
            		Mostrar más
          		</Button>
			</CardActions>
      <Collapse in={this.state.expanded} timeout="auto">
				<CardContent className={classes.expandedList}>
					{listExtra.sort((a,b) =>  {
						if(a.aut_url < b.aut_url) return -1
    					if(a.aut_url > b.aut_url) return 1
    					return 0
    				}).splice(5).map( a => <Button key={a.aut_id} className={classes.authorButton} component={Link} to={"/frases-de/"+ a.aut_url }>{a.aut_nombre}</Button>)}
				</CardContent>
			</Collapse>
		</Card>
	}
}

export default withStyles(styles)(AuthorLetter);