import React from 'react';
import {API_URL} from '../constants';

export function fetchApi( url, post = {}, contentType = 'application/json'){
    const apiUrl = API_URL + url
    let requestOptions = {}
    switch( contentType ){
        case 'application/x-www-form-urlencoded':
            let formData = []
            Object.keys(post).map( (key) => {
                let value = post[key]
                formData.push(key +"=" + value)
                return true
            })            

            requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': contentType,
                },
                body: formData.join("&"),
            }
        break
        default:
            requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': contentType,
                },
                body: JSON.stringify(post),
            }
        break
    }

    return fetch(apiUrl, requestOptions)
        .then(  response => response.json(),
                error => { console.log('An error occurred.', error, apiUrl) } )
        .then( json => {
            if (!json){
                return Promise.reject(json);                
            }else if(!json.success) { 
                if( json.errors ){
                    let errors = ""
                    if(  Array.isArray(json.errors) ){
                        errors =    (<div>
                            <strong>Error:</strong>
                                <ul>{
                                json.errors.map( (error,i) => {
                                    return (<li key={"errorList"+ i}>{error}</li>)
                                })
                            }</ul>
                            <i>{json.debug}</i>
                            <i>{json.message}</i>

                        </div>)  
                    }else{
                        errors =    (<div>
                            <strong>Error:</strong>
                            <ul>
                                <li>{json.errors}</li>
                            </ul>                               
                            <i>{json.debug}</i>
                            <i>{json.message}</i>

                        </div>)  
                    }
                    return Promise.reject(errors);
                }else{
                    return Promise.reject(json);
                }
            }

            return json;
        })
}
