import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import SharePageContainer from './sharePageContainer/SharePageContainer'

import Section from "./Section"

const styles = theme => ({})

function ShareCol(props){    	
	const { url, title } = props

	return <Section title="Compartir"
			content ={<SharePageContainer url={ url } title={title}
				view="columnLabel" />} />
}

export default withStyles(styles)(ShareCol);