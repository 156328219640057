import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import Section from "../../Section"

import TopicListContainer from '../../topic/topicListContainer/TopicListContainer'

const styles = theme => ({})

function SideLeft(props){    	
	const { topicList } = props

	return <div>
		<TopicListContainer ids={topicList.filter( t => t.featured === 1).map( t => t.id)} />
		<Section title="Temas"
			content={<TopicListContainer view="grid" ids={topicList.filter( t => t.featured === 0).map( t => t.id)} />}
		/>
	</div>
}

export default withStyles(styles)(SideLeft);