
import React, {Component} from 'react';
import { connect } from 'react-redux'

import AuthorInfo from './authorInfo/AuthorInfo';
import AuthorInfoXs from './authorInfo/AuthorInfoXs';

import Hidden from '@material-ui/core/Hidden';

import { authorActions } from '../../../actions'


class AuthorInfoContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			authorUrl: "",
			author: ""
		}
	}

	loadAuthor( dispatch, storeAuthor, authorUrl ){
		if( !authorUrl ) return false

		let authorExist = false
	
		if( storeAuthor && !storeAuthor.isFetching && storeAuthor.list ){
			let a = ""
			Object.keys(storeAuthor.list).map( i => {
				const aut = storeAuthor.list[i]
				if( aut.aut_url === authorUrl ){
					a = aut
					authorExist = true 
				}
				return true
			})

			this.setState({
				author: a
			})
		}

		if( !authorExist ){
			dispatch( authorActions.loadByUrl( authorUrl ) )
			return false
		}

		return true
	}

	componentWillMount(){
		const {dispatch,authorUrl,storeAuthor} = this.props

		this.setState({
			authorUrl: authorUrl
		})

		this.loadAuthor( dispatch, storeAuthor, authorUrl)
  	}

	componentWillReceiveProps( nextProps ){	
		const {dispatch,storeAuthor} = nextProps
		const {authorUrl} = this.state
		
		let stateUrl = authorUrl

		if( nextProps.authorUrl && stateUrl !== nextProps.authorUrl ){
			this.setState({
				authorUrl: nextProps.authorUrl
			})
			stateUrl = nextProps.authorUrl
			if( !storeAuthor.list  || !storeAuthor.list[nextProps.authorUrl] ){
				dispatch( authorActions.loadByUrl( nextProps.authorUrl ) )
				return false
			}
		}
		
		this.loadAuthor( dispatch, storeAuthor, authorUrl)
		
	}
	
	render(){
		const {author} = this.state
		if( ! author ) return false

		return <div>
			<Hidden smDown>
				<AuthorInfo 
					author={author}
					getAuthorRandom={this.props.getAuthorRandom} />
			</Hidden>
			<Hidden mdUp>
				<AuthorInfoXs 
					author={author}
					getAuthorRandom={this.props.getAuthorRandom} />
			</Hidden>
		</div>
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeAuthor: state.author,     
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorInfoContainer);
