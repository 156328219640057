import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import QuoteDoneContainer from '../../quoteDoneContainer/QuoteDoneContainer'
import TopicListContainer from '../../../topic/topicListContainer/TopicListContainer'
import Section from "../../../Section"

const styles = theme => ({
	section:{
		marginBottom: theme.spacing.unit * 8,
	},
	title:{
		marginBottom: theme.spacing.unit * 4,
	},
})

function Main(props){    	
	const { classes, topicList } = props

	return <div>
		<div className={classes.section}>
			<QuoteDoneContainer/>
		</div>

		<Section title="Temas"
			content={<TopicListContainer ids={topicList.filter( t => t.featured === 1).map(t => t.id)} />
}
		/>
	</div>
}

export default withStyles(styles)(Main);