import { quoteService } from '../services';
import { alertActions } from './';


export const quoteActions = {
    loadById,
    loadByIds,
    loadByPag,
    loadByAuthor,
    cookieGetLike,
    addLike,
    delLike,
    addGameDone,
}

export const QUOTE_LOAD_ID_REQUEST = 'QUOTE_LOAD_ID_REQUEST'
export const QUOTE_LOAD_ID_SUCCESS = 'QUOTE_LOAD_ID_SUCCESS'
export const QUOTE_LOAD_ID_FAILURE = 'QUOTE_LOAD_ID_FAILURE'

function loadById( id ) {
    return dispatch => {
        dispatch(request( ));   
        quoteService.loadById( id )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: QUOTE_LOAD_ID_REQUEST } 
    }
    function success(rows) { return { type: QUOTE_LOAD_ID_SUCCESS, rows } }
    function failure(error) { return { type: QUOTE_LOAD_ID_FAILURE, error } }
}

export const QUOTE_LOAD_IDS_REQUEST = 'QUOTE_LOAD_IDS_REQUEST'
export const QUOTE_LOAD_IDS_SUCCESS = 'QUOTE_LOAD_IDS_SUCCESS'
export const QUOTE_LOAD_IDS_FAILURE = 'QUOTE_LOAD_IDS_FAILURE'

function loadByIds( ids ) {
    return dispatch => {
        dispatch(request( ));   
        quoteService.loadByIds( ids )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: QUOTE_LOAD_IDS_REQUEST } 
    }
    function success(rows) { return { type: QUOTE_LOAD_IDS_SUCCESS, rows } }
    function failure(error) { return { type: QUOTE_LOAD_IDS_FAILURE, error } }
}

export const QUOTE_LOAD_PAG_REQUEST = 'QUOTE_LOAD_PAG_REQUEST'
export const QUOTE_LOAD_PAG_SUCCESS = 'QUOTE_LOAD_PAG_SUCCESS'
export const QUOTE_LOAD_PAG_FAILURE = 'QUOTE_LOAD_PAG_FAILURE'

function loadByPag( iPag ) {
    return dispatch => {
        dispatch(request( ));   
        quoteService.loadByPag( iPag )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: QUOTE_LOAD_PAG_REQUEST } 
    }
    function success(rows) { return { type: QUOTE_LOAD_PAG_SUCCESS, rows } }
    function failure(error) { return { type: QUOTE_LOAD_PAG_FAILURE, error } }
}

export const QUOTE_LOAD_AUTHOR_REQUEST = 'QUOTE_LOAD_AUTHOR_REQUEST'
export const QUOTE_LOAD_AUTHOR_SUCCESS = 'QUOTE_LOAD_AUTHOR_SUCCESS'
export const QUOTE_LOAD_AUTHOR_FAILURE = 'QUOTE_LOAD_AUTHOR_FAILURE'

function loadByAuthor( sAuthor ) {
    return dispatch => {
        dispatch(request( sAuthor ));   
        quoteService.loadByAuthor( sAuthor )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: QUOTE_LOAD_AUTHOR_REQUEST } 
    }
    function success(rows) { return { type: QUOTE_LOAD_AUTHOR_SUCCESS, rows } }
    function failure(error) { return { type: QUOTE_LOAD_AUTHOR_FAILURE, error } }
}

export const QUOTE_COOKIE_GET_LIKE_REQUEST = 'QUOTE_COOKIE_GET_LIKE_REQUEST'
export const QUOTE_COOKIE_GET_LIKE_SUCCESS = 'QUOTE_COOKIE_GET_LIKE_SUCCESS'
export const QUOTE_COOKIE_GET_LIKE_FAILURE = 'QUOTE_COOKIE_GET_LIKE_FAILURE'

function cookieGetLike( ids ) {
    return dispatch => {
        dispatch(request( ids ));   
        dispatch(success(ids));                   
    };

    function request( ) { 
        return { type: QUOTE_COOKIE_GET_LIKE_REQUEST } 
    }
    function success(rows) { return { type: QUOTE_COOKIE_GET_LIKE_SUCCESS, rows } }
//    function failure(error) { return { type: QUOTE_COOKIE_GET_LIKE_FAILURE, error } }
}

export const QUOTE_ADD_LIKE_REQUEST = 'QUOTE_ADD_LIKE_REQUEST'
export const QUOTE_ADD_LIKE_SUCCESS = 'QUOTE_ADD_LIKE_SUCCESS'
export const QUOTE_ADD_LIKE_FAILURE = 'QUOTE_ADD_LIKE_FAILURE'

function addLike( id ) {
    return dispatch => {
        dispatch(request( ));   
        quoteService.addLike( id )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: QUOTE_ADD_LIKE_REQUEST } 
    }
    function success(rows) { return { type: QUOTE_ADD_LIKE_SUCCESS, rows } }
    function failure(error) { return { type: QUOTE_ADD_LIKE_FAILURE, error } }
}

export const QUOTE_DEL_LIKE_REQUEST = 'QUOTE_DEL_LIKE_REQUEST'
export const QUOTE_DEL_LIKE_SUCCESS = 'QUOTE_DEL_LIKE_SUCCESS'
export const QUOTE_DEL_LIKE_FAILURE = 'QUOTE_DEL_LIKE_FAILURE'

function delLike( id ) {
    return dispatch => {
        dispatch(request( ));   
        quoteService.delLike( id )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: QUOTE_DEL_LIKE_REQUEST } 
    }
    function success(rows) { return { type: QUOTE_DEL_LIKE_SUCCESS, rows } }
    function failure(error) { return { type: QUOTE_DEL_LIKE_FAILURE, error } }
}

export const QUOTE_ADD_GAME_DONE_REQUEST = 'QUOTE_ADD_GAME_DONE_REQUEST'
export const QUOTE_ADD_GAME_DONE_SUCCESS = 'QUOTE_ADD_GAME_DONE_SUCCESS'
export const QUOTE_ADD_GAME_DONE_FAILURE = 'QUOTE_ADD_GAME_DONE_FAILURE'

function addGameDone( id ) {
    return dispatch => {
        dispatch(request( id ))
        dispatch(success(id))        
    }

    function request( ) { 
        return { type: QUOTE_ADD_GAME_DONE_REQUEST } 
    }
    function success(rows) { return { type: QUOTE_ADD_GAME_DONE_SUCCESS, rows } }
}