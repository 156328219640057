import React from 'react';

import Avatar from '@material-ui/core/Avatar';

import {Card,CardHeader,CardContent} from '@material-ui/core/';

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';


import { getAuthorImg } from '../../../../helpers'

const styles = theme => ({
  	card: {
  		marginBottom: theme.spacing.unit * 4,
  	},
  	avatar:{
    	width: 60,
    	height: 60,
  	},
});

function AuthorInfo(props){    	
	const {author, classes } = props
	if( !author ) return false
	
	return <Card className={classes.card}>
			<CardHeader
          		avatar={
            		<Avatar aria-label={author.aut_nombre} 
            			alt={author.aut_nombre} 
            			src={getAuthorImg(author.aut_url)}
            			className={classes.avatar} />
          		}

          		title={author.aut_nombre}
          		subheader={author.country_name}
        	/>		
	        <CardContent classNAme={classes.contentTitle}>
		        <Typography component="p">
		          	{author.description_short}
		        </Typography>
			</CardContent>
	</Card>		
}

export default withStyles(styles)(AuthorInfo);