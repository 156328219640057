
import React, {Component} from 'react';
import { connect } from 'react-redux'

import {isEmpty,drop,shuffle }from 'lodash'

import Game from './game/Game';
import { loadQuoteList,loadAuthorMinList } from '../../../helpers'

import { quoteActions } from '../../../actions'
import { quoteService } from '../../../services'


class GameContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {	
			questionList: [],
			snackOpen: false,
			quoteCurrent: "",
			quoteList: "",
			doneOpen: false,	
			isDoneList: false,			
			prevStatus: "",
			authorList: []
		}
	}
	
	componentWillMount(){
		if( this.props.doneOpen ){
			this.setState({doneOpen: this.props.doneOpen})
		}
		if( this.props.isDoneList ){
			this.setState({isDoneList: this.props.isDoneList})
		}

		this.componentWillReceiveProps(this.props)

	}

	componentWillReceiveProps( nextProps ){
		const {storeQuote,storeAuthor} = nextProps
		
		if( storeQuote && storeQuote.isFetching ) return false
		if( storeAuthor && storeAuthor.isFetching ) return false		

		if( !this.state.quoteCurrent){
			this.newQuestion()	
		}
	}

	handleSnackClick = state => () => {
    	this.setState({ snackOpen: true, ...state });
  	};

  	handleSnackClose = () => {
    	this.setState({ snackOpen: false });
  	};

	loadQuestions = ( ) => {
		const {dispatch} = this.props

		quoteService.loadGame().then( res => {

			const questionList = res.rows
			this.setState({
    			questionList: questionList
    		})

			const  ql = questionList.map( r => r.question.id )
			let  al = []
			res.rows.map( r => {
				al = al.concat(r.answer)
				return true 
			})
			
			loadQuoteList( ql.map( id => { return {id: id} } ), '', dispatch )
			loadAuthorMinList( al, '', dispatch )


			return true
		})
	}

	newQuestion = () => {		
		const {storeQuote} = this.props		

 		if( isEmpty(this.state.questionList) ){
 			this.loadQuestions()
 			return false
 		} 		

		let questionList = this.state.questionList
		if( !questionList ) return false

		const questionId = questionList[0].question.id
		const quoteNew = storeQuote.list[questionId]
		let answer = questionList[0].answer
		answer = shuffle(answer)
		
		questionList = drop(questionList)
		

		//const answer = this.getAnswer(quoteNew)
		this.setState({
			questionList: questionList,
			quoteCurrent: quoteNew,
			answer: answer,
		})
	}

	setStatus = ( correct, answer ) => {
		const {storeAuthor} = this.props
		const {quoteCurrent} = this.state

		let status = {
			correct: "",
			quote: "",
			author: "",
			answer: "",
			snackClass: "",
			snackIcon: "",
		}
		status.question = quoteCurrent
		status.author = storeAuthor.min[quoteCurrent.autor_id]
		status.answer = storeAuthor.min[answer]
		status.correct = correct	

		if( correct ){
			status.snackClass = "success"
		}else{
			status.snackClass = "error"
		}

		return status
	}

	/*getAnswer = ( quoteCurrent ) =>{
		const {authorList} = this.state

		let list = [...authorList]
		if( !list ) return false	
		if( !quoteCurrent ) return false	

		let answer = []			

		answer = answer.concat( list.filter( a => a.aut_id === quoteCurrent.autor_id ) )

		let index = ""		
		for (var i = 0; i < 5; i++) {
			index = Math.floor(Math.random() * list.length)
			answer.push(list[index])
			list.splice(index, 1);
    	} 
    	answer = shuffle(answer)

    	return answer
	}*/

	handleDoneOpen = () => {
    	this.setState(state => ({ doneOpen: !state.doneOpen }));
  	};

	handleClickAnswer = ( id ) => {
		const {dispatch} = this.props
		const {quoteCurrent} = this.state
		
		let prevStatus = ""
		if( quoteCurrent.autor_id === id ){
			prevStatus = this.setStatus( true, id)
		}else{
			prevStatus = this.setStatus( false, id)
		}

		dispatch( quoteActions.addGameDone({
			id: id,
			status: prevStatus}) 
		)

		this.setState({
			snackOpen: true,
			prevStatus: prevStatus,
		})

		this.newQuestion()
	}

	render(){
		const {quoteCurrent,prevStatus,answer,isDoneList,doneOpen} = this.state
		if (!quoteCurrent || !answer) return false

		return <Game 
			snackOpen={this.state.snackOpen}		
			handleSnackClose={this.handleSnackClose}		
			question={quoteCurrent} 
			answer={this.state.answer}
			prevStatus={prevStatus}
			isDoneList={isDoneList}
			doneOpen={doneOpen}
			handleDoneOpen={this.handleDoneOpen}
			handleClickAnswer={this.handleClickAnswer} />
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeQuote: state.quote,  
		storeAuthor: state.author,     
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GameContainer);
