
import React, {Component} from 'react';
import { connect } from 'react-redux'
import Hidden from '@material-ui/core/Hidden';

import update from 'immutability-helper';

import Page2Col from '../../Page2Col'
import Page2ColXs from '../../Page2ColXs'

import Main from './main/Main'
import Side from './side/Side'

import { loadQuoteList } from '../../../helpers'

import { quoteService, authorService } from '../../../services'

class QuoteAuthorContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			author: {},
			authorUrl: "",
			quoteList: ""
		}
	}

	componentWillMount(){
		let authorUrl = this.props.match.params["authorUrl"]
		if( !authorUrl ) {
			authorUrl = this.props["authorUrl"]
		}
		if( !authorUrl ) return false
		
		this.loadData(authorUrl)	
  	}

	componentWillReceiveProps( nextProps ){	
		const {authorUrl,storeQuote} = nextProps
		if( authorUrl && this.state.authorUrl !== authorUrl ){
			
			if( !storeQuote.author  || !storeQuote.author[authorUrl] ){
				this.loadData(authorUrl)				

				return false
			}
		}
		
		if( storeQuote && storeQuote.author &&this.state.authorUrl && storeQuote.author ){			
			this.setState({
				quoteList: storeQuote.author[this.state.authorUrl]
			})
		}
	}

	loadData( authorUrl ){
		const {dispatch} = this.props

		authorService.loadByUrl( authorUrl ).then( res => {
			const author = res.rows

			quoteService.loadByAuthorId( author.aut_id ).then( res => {
				let newState = update(this.state, {
					authorUrl: {$set: authorUrl},  	
					author: {$set: author},  				
  					quoteList: {$set: res.rows.result.map( r => r.id )}
				})
				this.setState(newState)
				this.setHeaders()
				loadQuoteList( res.rows.result, '', dispatch )
			})
		})	
	}


	setHeaders() {
      	document.title = "Frases de "+ this.state.author.aut_nombre
      	document.description = "Frases bonitas, frases de amor, frases para reflexionar... Todo tipo de frases para enriquecer la vida"
  	}
	


	render(){
		const {quoteList,author} = this.state
		const {getAuthorRandom} = this.props

		return <div>
			<Hidden smDown>
				<Page2Col 
					getAuthorRandom={getAuthorRandom}
					main={<Main quoteList={quoteList} author={author} />}
					right={<Side author={author} getAuthorRandom={this.props.getAuthorRandom} />} />
			</Hidden>
			<Hidden mdUp>
				<Page2ColXs
					getAuthorRandom={getAuthorRandom}
					main={<Main quoteList={quoteList} author={author} />}
					right={<Side author={author} getAuthorRandom={this.props.getAuthorRandom} />} />
			</Hidden>
		</div>	
		
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeQuote: state.quote,     
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteAuthorContainer);
