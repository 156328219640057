
import React, {Component} from 'react';

import QuoteAuthorContainer from '../quoteAuthorContainer/QuoteAuthorContainer'

import { authorService } from '../../../services';


class RandomQuoteAuthorContainer extends Component { 

	constructor(props) {
		super(props);

		this.state = {
			authorUrl: "",
		}

	}

	componentWillMount(){
		this.getAuthorRandom()
	}

	getAuthorRandom = () => {
		authorService.loadRandom().then( res => {
			this.setState({
				authorUrl: res.rows
			})
		})
	}

	render(){
		const {authorUrl} = this.state
		if(!authorUrl) return false

		return <QuoteAuthorContainer
			authorUrl={authorUrl}
			getAuthorRandom={this.getAuthorRandom}
			{...this.props}
		/>   
	} 
}

export default RandomQuoteAuthorContainer;