import { authorService } from '../services';
import { alertActions } from './';


export const authorActions = {
    loadById,
    loadByIds,
    loadByUrl,
    loadByUrls,
    loadMin,
    loadMinByIds,
}

export const AUTHOR_LOAD_ID_REQUEST = 'AUTHOR_LOAD_ID_REQUEST'
export const AUTHOR_LOAD_ID_SUCCESS = 'AUTHOR_LOAD_ID_SUCCESS'
export const AUTHOR_LOAD_ID_FAILURE = 'AUTHOR_LOAD_ID_FAILURE'

function loadById( id ) {
    return dispatch => {
        dispatch(request( ));   
        authorService.loadById( id )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: AUTHOR_LOAD_ID_REQUEST } 
    }
    function success(rows) { return { type: AUTHOR_LOAD_ID_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_LOAD_ID_FAILURE, error } }
}

export const AUTHOR_LOAD_IDS_REQUEST = 'AUTHOR_LOAD_IDS_REQUEST'
export const AUTHOR_LOAD_IDS_SUCCESS = 'AUTHOR_LOAD_IDS_SUCCESS'
export const AUTHOR_LOAD_IDS_FAILURE = 'AUTHOR_LOAD_IDS_FAILURE'

function loadByIds( ids ) {
    return dispatch => {
        dispatch(request( ));   
        authorService.loadByIds( ids )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: AUTHOR_LOAD_IDS_REQUEST } 
    }
    function success(rows) { return { type: AUTHOR_LOAD_IDS_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_LOAD_IDS_FAILURE, error } }
}

export const AUTHOR_LOAD_URL_REQUEST = 'AUTHOR_LOAD_URL_REQUEST'
export const AUTHOR_LOAD_URL_SUCCESS = 'AUTHOR_LOAD_URL_SUCCESS'
export const AUTHOR_LOAD_URL_FAILURE = 'AUTHOR_LOAD_URL_FAILURE'

function loadByUrl( url ) {
    return dispatch => {
        dispatch(request( ));   
        authorService.loadByUrl( url )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: AUTHOR_LOAD_URL_REQUEST } 
    }
    function success(rows) { return { type: AUTHOR_LOAD_URL_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_LOAD_URL_FAILURE, error } }
}

export const AUTHOR_LOAD_URLS_REQUEST = 'AUTHOR_LOAD_URLS_REQUEST'
export const AUTHOR_LOAD_URLS_SUCCESS = 'AUTHOR_LOAD_URLS_SUCCESS'
export const AUTHOR_LOAD_URLS_FAILURE = 'AUTHOR_LOAD_URLS_FAILURE'

function loadByUrls( urls ) {
    return dispatch => {
        dispatch(request( ));   
        authorService.loadByUrls( urls )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: AUTHOR_LOAD_URLS_REQUEST } 
    }
    function success(rows) { return { type: AUTHOR_LOAD_URLS_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_LOAD_URLS_FAILURE, error } }
}

export const AUTHOR_LOAD_MIN_REQUEST = 'AUTHOR_LOAD_MIN_REQUEST'
export const AUTHOR_LOAD_MIN_SUCCESS = 'AUTHOR_LOAD_MIN_SUCCESS'
export const AUTHOR_LOAD_MIN_FAILURE = 'AUTHOR_LOAD_MIN_FAILURE'

function loadMin( ids ) {
    return dispatch => {
        dispatch(request( ));   
        authorService.loadMin()
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: AUTHOR_LOAD_MIN_REQUEST } 
    }
    function success(rows) { return { type: AUTHOR_LOAD_MIN_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_LOAD_MIN_FAILURE, error } }
}

export const AUTHOR_LOAD_MIN_BY_IDS_REQUEST = 'AUTHOR_LOAD_MIN_BY_IDS_REQUEST'
export const AUTHOR_LOAD_MIN_BY_IDS_SUCCESS = 'AUTHOR_LOAD_MIN_BY_IDS_SUCCESS'
export const AUTHOR_LOAD_MIN_BY_IDS_FAILURE = 'AUTHOR_LOAD_MIN_BY_IDS_FAILURE'

function loadMinByIds( ids ) {
    return dispatch => {
        dispatch(request( ids ));   
        authorService.loadMinByIds( ids)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: AUTHOR_LOAD_MIN_BY_IDS_REQUEST } 
    }
    function success(rows) { return { type: AUTHOR_LOAD_MIN_BY_IDS_SUCCESS, rows } }
    function failure(error) { return { type: AUTHOR_LOAD_MIN_BY_IDS_FAILURE, error } }
}