import isEmpty from 'lodash/isEmpty'
import { authorActions } from '../actions'

export function loadAuthorList ( authorList, storeAuthor, dispatch ) {

    let authorIdList = []
    authorList.map( (id) => {
        if( !storeAuthor || !storeAuthor.list || !storeAuthor.list[id] ){
            authorIdList.push(id)
        }
        return true
    })

    if( !isEmpty(authorIdList) ){
        dispatch( authorActions.loadByIds( authorIdList ) )
    }

    return true
}

export function loadAuthorMinList( authorList, storeAuthor, dispatch ) {
    if( storeAuthor && storeAuthor.isFetching ) return false

    let authorIdList = []
    if( authorList){
        authorList.map( (id) => {
            if( !storeAuthor || !storeAuthor.min || !storeAuthor.min[id] ){
                authorIdList.push(id)
            }
            return true
        })
    }

    if( !isEmpty(authorIdList) ){
        dispatch( authorActions.loadMinByIds( authorIdList ) )
    }else{
        dispatch( authorActions.loadMin( ) )        
    }

    return true
}

export function getAuthorImg( authorUrl ){
    return "https://frasede.com/assets/content/autores/"+ authorUrl +".jpg"
}