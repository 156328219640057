import React from 'react';

import {Card,CardContent,CardActions,CardMedia} from '@material-ui/core/';

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
import RandomButton from '../../../author/RandomButton'

import { getAuthorImg } from '../../../../helpers'

const styles = theme => ({
  	authorInfo: {
  	},
  	media: {
  		width: '100%',
  		height: '100%',
  		minWidth: '150px',
  		minHeight: '150px'
  	},
  	country: {
    	marginBottom: theme.spacing.unit * 2,
  	},
  	button: {
   		margin: theme.spacing.unit,
   		backgroundColor: theme.palette.secondary.dark,
   		color: theme.palette.secondary.textDark
  	},
  	actions: {
  		justifyContent: 'center',
  	}
});

function AuthorInfo(props){    	
	const {author, classes, getAuthorRandom } = props
	if( !author ) return false

	return <Card className={classes.authorInfo}>			
		<CardMedia className={classes.media}
			component="img"
            image={getAuthorImg(author.aut_url)}
            title={author.aut_nombre} />
        <CardContent>
	        <Typography variant="headline" component="h2">
				{author.aut_nombre}
	        </Typography>
	        <Typography className={classes.country} color="textSecondary">
	         	{author.country_name}
	        </Typography>
	        <Typography component="p">
	          	{author.description_short}
	        </Typography>
		</CardContent>
		<CardActions className={classes.actions}>
			<RandomButton getAuthorRandom={getAuthorRandom}/>    		
		</CardActions>
	</Card>		
}

export default withStyles(styles)(AuthorInfo);