
import React, {Component} from 'react';
import { connect } from 'react-redux'

import Page2Col from '../../Page2Col'

import Main from './main/Main'
import Side from './side/Side'

import { loadQuoteList } from '../../../helpers'

class QuotePageContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			authorUrl: "",
			quoteId: "",
			quote: ""
		}
	}

	getQuoteUrl = () => {
		const {authorUrl, quoteId} = this.state
		return "https://frasede.com/frases-de/"+ authorUrl +"/"+ quoteId
	}

	componentWillMount(){
		const {dispatch} = this.props
		let authorUrl = this.props.match.params["authorUrl"]
		if( !authorUrl ) {
			authorUrl = this.props["authorUrl"]
		}

		let quoteId = this.props.match.params["quoteId"]
		if( !quoteId ) {
			quoteId = this.props["quoteId"]
		}
		if( !quoteId ) return false
		
		this.setState({
			authorUrl: authorUrl,
			quoteId: quoteId
		})

		const quoteArray = [{ 
			id: parseInt(quoteId,10),
			autor_url: authorUrl}]

		loadQuoteList( quoteArray, '', dispatch )
  	}

	componentWillReceiveProps( nextProps ){	
		const {authorUrl,storeQuote,storeAuthor} = nextProps

		if( authorUrl && this.state.authorUrl !== authorUrl ){
			this.setState({
				authorUrl: authorUrl
			})
			if( !storeQuote.author  || !storeQuote.author[authorUrl] ){
				return false
			}
		}
		
		if( this.state.authorUrl && storeAuthor.list ){		
			let author = ""
			Object.keys(storeAuthor.list).map( i => storeAuthor.list[i] ).filter( a => a.aut_url === this.state.authorUrl).map( a => {
				author = a 
				return true 
			})	
			this.setState({
				author: author
			})
		}

		if( this.state.quoteId && storeQuote.list ){			
			this.setState({
				quote: storeQuote.list[this.state.quoteId]
			})
		}
	}

	render(){
		const {quoteId, quote, author} = this.state
		if( !quote || !author ) return false 
		const title = quote.frs_frase +" - " + author.aut_nombre

		return <Page2Col 
			main={<Main quoteId={quoteId} page="quote" />}
			right={<Side url={ this.getQuoteUrl()} title={title} />}
		/>
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeQuote: state.quote,  
		storeAuthor: state.author,   
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotePageContainer);
