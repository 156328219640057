
import React, {Component} from 'react';
import { connect } from 'react-redux'

import QuoteDoneList from './quoteDoneList/QuoteDoneList';

class QuoteDoneContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {	
			quoteDone: [],
		}
	}	
	
	componentWillMount(){
		this.componentWillReceiveProps(this.props)
	}

	componentWillReceiveProps( nextProps ){
		const {storeQuote} = nextProps
		if( !storeQuote || !storeQuote.done ) return false

		if( storeQuote && storeQuote.isFetching ) return false

		let quoteDone = !storeQuote || !storeQuote.done?[]:storeQuote.done 

		this.setState({
			quoteDone: quoteDone
		})
	}


	render(){
		const {quoteDone} = this.state
		if (!quoteDone) return false		
		
		return <QuoteDoneList quoteDoneList={quoteDone} />
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeQuote: state.quote,  
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteDoneContainer);
