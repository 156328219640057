import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import TopicListContainer from '../../../topic/topicListContainer/TopicListContainer'

import Section from "../../../Section"



const styles = theme => ({})

function Side(props){    	
	const { topicList } = props
	if( !topicList ) return false
	return <div>
		<Section content={<TopicListContainer ids={topicList.filter( t => t.featured === 1).map( t => t.id)} />} />
		<Section title="Temas" content={<TopicListContainer view="grid" ids={topicList.filter( t => t.featured === 0).map( t => t.id)} />} />
	</div>

}

export default withStyles(styles)(Side);