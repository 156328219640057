import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import {  NavLink, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import Hidden from '@material-ui/core/Hidden';

import MenuIcon from '@material-ui/icons/Menu';

import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Drawer from '@material-ui/core/Drawer';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import HomeIcon from '@material-ui/icons/Home';
import GroupIcon from '@material-ui/icons/Group';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import GamesIcon from '@material-ui/icons/Games';



import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  	flex: {
   		flexGrow: 1,
  	},
  	likeButton: {
  		color: 'red'
  	},
  	badge: {
   		top: - theme.spacing.unit * 1,
    	right: - theme.spacing.unit  * 2,
    	// The border color match the background color.
    	border: `2px solid ${
      		theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    	}`,
  	},
  	list: {
    	width: 250,
  	},
})

let elementsMenu = [
	{
		name:	"Frases de Hoy",
		url:	"/",
		icon: 	<HomeIcon />
	},
	{
		name:	"Autor Aleatorio",
		url:	"/autor-aleatorio",
		icon: 	<ShuffleIcon />
	},
	{
		name:	"Juego de Frases",
		url:	"/juego-frases",
		icon: 	<GamesIcon />
	},
	{
		name:	"Autores",
		url:	"/autores-frases-bonitas",
		icon: 	<GroupIcon />
	}
]
class Menu extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			drawerOpen: false
		}

	}

	toggleDrawer = (open) => () => {
    	this.setState({ drawerOpen: open });
  	};

	render(){
		const {classes,likeNum,getAuthorRandom} = this.props
		let buttonIcon = <IconButton aria-label="Autor Aleatorio"
					component={Link} to={"/autor-aleatorio"} >
						<ShuffleIcon />
				</IconButton>
		if( getAuthorRandom ){
			buttonIcon = <IconButton aria-label="Autor Aleatorio"
					component={Link} to={"/autor-aleatorio"} onClick={getAuthorRandom} >
						<ShuffleIcon />
				</IconButton>
		}

		return <AppBar position="sticky" color="primary">
			<Toolbar>
				<IconButton className={classes.menuButton} 
					color="inherit" aria-label="Menu"
					onClick={this.toggleDrawer( true ) }>
	            	<MenuIcon />
	          	</IconButton>
				<Typography variant="h6" color="inherit" className={classes.flex} component={NavLink} to="/" >
				   Frase De
				</Typography>             
				
				<Hidden smDown>
				{
					elementsMenu.map( (m) => {
						if( getAuthorRandom && m.url === "/autor-aleatorio"){
							return <Button color="inherit" component={NavLink} to={m.url} onClick={getAuthorRandom} >{m.name}</Button>
						}
						
						return <Button color="inherit" component={NavLink} to={m.url}>{m.name}</Button>
					})
				}
				</Hidden>				

				{buttonIcon}


				{likeNum > 0 ?<IconButton aria-label="Like" className={classes.likeButton}
					component={NavLink} to={"/frases-preferidas"} >
					<Badge badgeContent={likeNum} color="secondary" classes={{ badge: classes.badge }}>
						<FontAwesomeIcon icon="heart"  /> 
					</Badge>
				</IconButton>
				:""}
			</Toolbar>
			<Drawer open={this.state.drawerOpen} onClose={this.toggleDrawer(false)}>
	        	<div className={classes.list}
	          		tabIndex={0}
	          		role="button"
	          		onClick={this.toggleDrawer( false) }
	          		onKeyDown={this.toggleDrawer( false) }>
	          		<List>
						{elementsMenu.map( (m) => <ListItem button component="a" 
							href={m.url}>
							<ListItemIcon>{m.icon}</ListItemIcon>
							<ListItemText primary={m.name} />							
						</ListItem>
						)}
						<Divider />
					</List>
	        	</div>
	      	</Drawer>
		</AppBar>
	}

}

export default withStyles(styles)(Menu);