import React, {Component} from 'react';
import { connect } from 'react-redux'

import Menu from './menu/Menu'

class MenuContainer extends Component { 

	constructor(props) {
		super(props);

		this.state = {
			likeNum: ""	
		}
	}

	componentWillReceiveProps( nextProps ){
		const {storeQuote } = nextProps
		if( storeQuote && storeQuote.like && !storeQuote.isFetching ){
			if( this.state.likeNum !== storeQuote.like.length ){
				this.setState({
					likeNum: storeQuote.like.length
				})
			}
		}
		
	}

	render(){
		const {likeNum} = this.state
		return  (
            <Menu 
            	{...this.props}
            	likeNum={likeNum}
			/>
		)
  }
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeQuote: state.quote,     
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
