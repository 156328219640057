import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import QuoteContainer from '../../quoteContainer/QuoteContainer'

const styles = theme => ({
	container: {
		height: "calc(100vh - 64px)",
		display: "flex",
		alignItems: "center",
	}
})

function Main(props){    	
	const { classes, quoteId, page } = props

	return <div className={classes.container}>
		<QuoteContainer id={quoteId} page={page} />
	</div>
}

export default withStyles(styles)(Main);