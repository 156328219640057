import {
  AUTHOR_LOAD_ID_REQUEST,
  AUTHOR_LOAD_ID_SUCCESS,
  AUTHOR_LOAD_ID_FAILURE,
  AUTHOR_LOAD_IDS_REQUEST,
  AUTHOR_LOAD_IDS_SUCCESS,
  AUTHOR_LOAD_IDS_FAILURE,
  AUTHOR_LOAD_URLS_REQUEST,
  AUTHOR_LOAD_URLS_SUCCESS,
  AUTHOR_LOAD_URLS_FAILURE,
  AUTHOR_LOAD_MIN_REQUEST,
  AUTHOR_LOAD_MIN_SUCCESS,
  AUTHOR_LOAD_MIN_FAILURE,
  AUTHOR_LOAD_MIN_BY_IDS_REQUEST,
  AUTHOR_LOAD_MIN_BY_IDS_SUCCESS,
  AUTHOR_LOAD_MIN_BY_IDS_FAILURE,  
} from '../actions/author'

import update from 'immutability-helper';


const author = (state = [], action) => {
	let newState = ""
	let stateList = Object.assign( {}, state.list )

	switch (action.type) {
		case AUTHOR_LOAD_ID_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case AUTHOR_LOAD_ID_SUCCESS:			
			stateList[action.rows.aut_id] =   action.rows

			newState = Object.assign( {}, state )
			newState = update( newState, {
				isFetching: {$set: false},
				list: {$set: stateList},				
			});

			return newState 

		case AUTHOR_LOAD_ID_FAILURE:
			return {};
		
		case AUTHOR_LOAD_IDS_REQUEST:
		case AUTHOR_LOAD_URLS_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case AUTHOR_LOAD_IDS_SUCCESS:	
		case AUTHOR_LOAD_URLS_SUCCESS:	
			action.rows.map( (a) => {
				stateList[a.aut_id] =   a	
				return true
			})		
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isFetching: {$set: false},
				list: {$set: stateList},				
			});

			return newState 
		case AUTHOR_LOAD_IDS_FAILURE:
		case AUTHOR_LOAD_URLS_FAILURE:
			return {};

		case AUTHOR_LOAD_MIN_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case AUTHOR_LOAD_MIN_SUCCESS:	
			action.rows.map( (a) => {
				stateList[a.aut_id] =   a	
				return true
			})		
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isFetching: {$set: false},
				isComplete: {$set: true},				
				min: {$set: stateList},				
			});

			return newState 
		case AUTHOR_LOAD_MIN_FAILURE:
			return {};
		case AUTHOR_LOAD_MIN_BY_IDS_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case AUTHOR_LOAD_MIN_BY_IDS_SUCCESS:	
			action.rows.map( (a) => {
				stateList[a.aut_id] =   a	
				return true
			})		
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isFetching: {$set: false},
				min: {$set: stateList},				
			});

			return newState 
		case AUTHOR_LOAD_MIN_BY_IDS_FAILURE:
			return {};
		default:
			return state
  	}
}

export default author