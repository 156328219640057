import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import QuoteListContainer from '../../../quote/quoteListContainer/QuoteListContainer'

const styles = theme => ({
	section:{
		marginBottom: theme.spacing.unit * 8,
	},
	title:{
		marginBottom: theme.spacing.unit * 4,
		color: theme.palette.primary.text,
	},
})

function Main(props){    	
	const {  quoteList  } = props

	return <QuoteListContainer quotes={quoteList} page="quoteLike" />
}

export default withStyles(styles)(Main);