import React, {Component} from 'react';

import Button from '@material-ui/core/Button';

import {
  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  RedditShareButton,
  EmailShareButton,
} from 'react-share';
import {
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  GooglePlusIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  EmailIcon,
} from 'react-share';

import SharePageRow from './sharePage/SharePageRow';
import SharePageColumnLabel from './sharePage/SharePageColumnLabel';

function buttonLabel( icon, text ){
	const buttonStyle = {
		width: "100%",
		display: "flex",
		justifyContent: "space-between"}
	const iconStyle = {
		flexGrow: 1,
		flexBasis: 1,
		width: "32px",
	}
	const labelStyle = {
		flexGrow: 4,
		flexBasis: 4,
		textAlign: "center"
	}

	return <Button style={buttonStyle}>
		<span style={iconStyle}>{icon}</span>
		<span style={labelStyle}>{text}</span>
	</Button>
}

function getButton( type, web ){
	const size = 32
	const round = true  
	
	const shareButtonIcon = {
		facebook:	<FacebookIcon size={size} round={round} />,
     	google:	<GooglePlusIcon size={size} round={round} />,
     	twitter:	<TwitterIcon size={size} round={round} />,
     	telegram:	<TelegramIcon size={size} round={round} />,
     	whatsapp:	<WhatsappIcon size={size} round={round} />,
     	email:	<EmailIcon size={size} round={round} />,
     	pinterest:	<PinterestIcon size={size} round={round} />,
     	linkedin:	<LinkedinIcon size={size} round={round} />,
     	reddit:	<RedditIcon size={size} round={round} />,
	}
	const shareButtonLabel = {
		facebook:	buttonLabel(
			<FacebookIcon size={size} round={round} />,
			'Facebook' ),
     	google:	buttonLabel(     	
     		<GooglePlusIcon size={size} round={round} />,
     		"Google Plus"),
     	twitter:	buttonLabel(
     		<TwitterIcon size={size} round={round} />,
     		"Twitter"),
     	telegram:	buttonLabel(
     		<TelegramIcon size={size} round={round} />,
     		"Telegram"),
     	whatsapp:	buttonLabel(
     		<WhatsappIcon size={size} round={round} />,
     		"WhatsApp"),
     	email:	buttonLabel(
     		<EmailIcon size={size} round={round} />,
     		"Enviar Email"),
     	pinterest:	buttonLabel(
     		<PinterestIcon size={size} round={round} />,
     		"Pinterest"),
     	linkedin:	buttonLabel(
     		<LinkedinIcon size={size} round={round} />,
     		"Linked In"),
     	reddit:	buttonLabel(
     		<RedditIcon size={size} round={round} />,
     		"Reddit"),
	}

	if( type === "columnLabel"){
		return shareButtonLabel[web]
	}else{
		return shareButtonIcon[web]
	}
}
  	function getOptions( type, url, title ){

		let share = {
        	facebook: <FacebookShareButton url={url} quote={title || ""} >
					{getButton(type,"facebook")}
         	</FacebookShareButton>,
        	google: <GooglePlusShareButton url={url} quote={title || ""} >
					{getButton(type,"google")}
			</GooglePlusShareButton>,
        	twitter: <TwitterShareButton url={url} quote={title || ""} >
					{getButton(type,"twitter")}
          </TwitterShareButton>,
        	telegram: <TelegramShareButton url={url} quote={title || ""} >
					{getButton(type,"telegram")}
          </TelegramShareButton>,
        	whatsapp: <WhatsappShareButton url={url} quote={title || ""} >
					{getButton(type,"whatsapp")}
        		</WhatsappShareButton>,
        	email: <EmailShareButton url={url} quote={title || ""} >
					{getButton(type,"email")}
        	</EmailShareButton>,

        	pinterest: <PinterestShareButton url={url} quote={title || ""} >
					{getButton(type,"pinterest")}
        	</PinterestShareButton>,
        	linkedin: <LinkedinShareButton url={url} quote={title || ""} >
					{getButton(type,"linkedin")}
        	</LinkedinShareButton>,
        	reddit: <RedditShareButton url={url} quote={title || ""} >
					{getButton(type,"reddit")}
        	</RedditShareButton>,
      }

      return share
  	}

class SharePageContainer extends Component {    	

	render() {
		const { url, title, view } = this.props
		if( !url ) return false

  
		const list = ['facebook','google','twitter','telegram','whatsapp','pinterest','email']

   	if( view === "columnLabel"){
      return <SharePageColumnLabel shareOptions={getOptions(view, url, title)} list={list}/>
    }else{
			return <SharePageRow shareOptions={getOptions(view, url, title)} list={list} />
    }
	}
}

export default SharePageContainer