import React, {Component} from 'react'
import { connect } from 'react-redux'

import Quote from './quote/Quote'
import { withCookies } from 'react-cookie'

class QuoteContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			id: "",
			quote: "",
			author: ""
		}
	}
	
	setQuote( dispatch, storeQuote, storeAuthor, id ){		
		if( !storeQuote 
			|| !storeQuote.list 
			|| !storeQuote.list[id]) return false	
		
		const q = storeQuote.list[id]	
		if( !q ) return false

		if( storeAuthor 
			&& storeAuthor.isFetching) return false

		if( !storeAuthor 
			|| !storeAuthor.list 
			|| !storeAuthor.list[q.autor_id] ) return false	

		const a = storeAuthor.list[q.autor_id]

		if( this.props.onQuoteLoad ){
			this.props.onQuoteLoad( q, a )
		}

		this.setState({
			quote: q,
			author: a
		})

		return true
	}

	componentWillMount(){		
		const {dispatch,storeAuthor,id,storeQuote} = this.props
		
		if( !id ) return false
		
		this.setState({
			id: this.props.id
		})
		
		this.setQuote( dispatch, storeQuote, storeAuthor, this.props.id )

		return true
  	}

	componentWillReceiveProps( nextProps ){
		const {dispatch,storeAuthor,storeQuote} = nextProps
		
		if( !storeQuote || !storeAuthor ) return false
		if( storeQuote.isFetching || storeAuthor.isFetching) return false
		
		if( !this.state.id ) return false		
		if( this.state.quote && this.state.author) return false

		this.setQuote( dispatch, storeQuote, storeAuthor, this.state.id )
	}
			

	render(){
		const { quote, author } = this.state
		if ( !quote || !author ) return false
		
		return <Quote 
			quote={quote}
			author={author}
			page={this.props.page} />
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeQuote: state.quote,
		storeAuthor: state.author,     
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(QuoteContainer));
