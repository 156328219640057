import React, {Component} from 'react';
import {  Link } from 'react-router-dom';


import { withStyles } from '@material-ui/core/styles'

import {Card,CardContent,CardActions,CardMedia} from '@material-ui/core/'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import {getTopicUrl,getTopicImgUrl} from "../../../../helpers"


const styles = theme => ({
	container: {
  	},
	
	card: {
		marginBottom: theme.spacing.unit * 2,
		justifyContent: 'space-between',

	},  	
  	details: {
   	display: 'flex',
  	},

  	cover: {
   	height: '150px',
   	width: '100%',
   	flexShrink: 0,
  	},  
  	content: {
  		flex: '1 0 auto'
  	},	
  	action: {
  		display: 'flex'
  	},
  	actionLeft: {
  		flexGrow: 1
  	},
});

class TopicBox extends Component {    	
	
	render() {
		const { topic, classes } = this.props
		if( !topic ) return false

    return <div>
  <Card className={classes.card}>
 		<CardMedia
         className={classes.cover}
         image={getTopicImgUrl(topic)}
         title={topic.title}
         alt={topic.title}
      />
 		<CardContent>
 			<Typography variant="headline" >{topic.name}</Typography>
 			<Typography component="p">{topic.description_short}</Typography>
      </CardContent>
      <CardActions>
      	<div className={classes.actionRight}>
      		<Link to={getTopicUrl(topic)} >
      			<Button>Ver</Button>
      		</Link>
      	</div>
      </CardActions>
    </Card>	
  </div>	
	}
}

export default withStyles(styles)(TopicBox);