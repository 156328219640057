import { combineReducers } from 'redux'

import alert from './alert'

import quote from './quote'
import author from './author'
import topic from './topic'

const fraseDeApp = combineReducers({
	alert,
	quote,
	author,
	topic,
})
 
export default fraseDeApp