import isEmpty from 'lodash/isEmpty'
import { quoteActions, authorActions } from '../actions'

export function loadQuoteList ( quoteList, storeQuote, dispatch ) {

    let quoteIdList = []
    let authorIdList = []
    let authorUrlList = []

    quoteList.map( (q) => {
        if( !storeQuote || !storeQuote.list || !storeQuote.list[q.id] ){
            quoteIdList.push(q.id)
            if( q.autor_id ){
                authorIdList.push(q.autor_id)
            }else if( q.autor_url ){
                authorUrlList.push(q.autor_url)
            }
        }
        return true
    })

    if( !isEmpty(quoteIdList) ){
        dispatch( quoteActions.loadByIds( quoteIdList ) )
    }
    if( !isEmpty(authorIdList) ){
        dispatch( authorActions.loadByIds( authorIdList ) )
    }
    if( !isEmpty(authorUrlList) ){
        dispatch( authorActions.loadByUrls( authorUrlList ) )
    }

    return true
}