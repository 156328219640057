
import React, {Component} from 'react';
import { connect } from 'react-redux'

import QuoteList from './quoteList/QuoteList';


class QuoteListContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			authorUrl: ""	
		}

	}

	render(){
		const { quotes } = this.props
		if( !quotes ) return false

		return <QuoteList {...this.props} />
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		quote: state.quote,     
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteListContainer);
