import React, {Component} from 'react';
import {  Link } from 'react-router-dom';

import classNames from 'classnames';

import {Card,CardContent,CardActions,CardMedia} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getAuthorImg, getQuoteUrl, getShareQuoteUrl, getAuthorUrl } from '../../../../helpers'

import SharePageContainer from '../../../sharePageContainer/SharePageContainer'
import QuoteLikeButtonContainer from '../../../quote/quoteLikeButtonContainer/QuoteLikeButtonContainer'

import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({	
	card: {
  		marginBottom: theme.spacing.unit * 2,
  	},  	
  	quote: {
   		display: 'flex',
  		justifyContent: 'space-between',   		
  	},
  	quoteMedia: {
   		height: '150px',
   		width: '150px',
   		flexShrink: 0,
   		flexGrow:1,
  	},  
  	quoteContent: {
  		flexDirection: 'column',
  	},	
  	action: {
  		display: 'flex',
  		justifyContent: 'space-between', 
  	},
  	actionLeft:{
  		flexGrow: 1
  	},
  	actionRight: {
  		flexGrow: 1,
  		textAlign: 'right'
  	},
  	shareBox: {
  		display: 'flex'
  	},
  	button: {
  		margin: theme.spacing.unit
  	},
  	buttonImportant: {
  		backgroundColor: theme.palette.primary.main,  		
  	},
  	buttonXsMore:{

  	},
  	shareContent: {
  		backgroundColor: theme.palette.secondary.light,
  	}

});

class Quote extends Component {    	
	state = { shareExpanded: false }

  	handleShareExpandClick = () => {
    	this.setState(state => ({ shareExpanded: !state.shareExpanded }));
  	}


	render() {
		const { quote, author,classes } = this.props
		if( !quote )return false

		const page = this.props.page || ""
		
		let showMedia = false
		let showMoreQuotes = false
		let showLinkQuote = false 
		if( page !== "quoteAuthor"){
			showMedia = true
			showMoreQuotes = true
	    }
	    if( page !== "quote" ){
	    	showMedia = false
	    	showLinkQuote = true
	    }

		return <Card className={classes.card}>
			<div className={classes.quote}>
				<CardContent className={classes.quoteContent}>
					<Typography variant="headline">{quote.frs_frase}</Typography>
					<Hidden smDown>		
          				<Typography variant="subheading" color="textSecondary">
            				{author.aut_nombre}
          				</Typography>
          			</Hidden>
          			<Hidden mdUp>
          				{ showMoreQuotes ? <div style={{display: "flex",alignItems: "center"}}>
          					<Link to={getAuthorUrl(author)}>
          						<Typography variant="subheading" color="textSecondary">
            						{author.aut_nombre}
          						</Typography>
          					</Link>
          					<IconButton component={Link} to={getAuthorUrl(author)} color="secondary">
								<FontAwesomeIcon icon="angle-right" />
		      				</IconButton>
		      				</div>:<Typography variant="subheading" color="textSecondary">
            					{author.aut_nombre}
          					</Typography>
						}
					</Hidden>
			   	</CardContent>  
			   	{ showMedia ? <CardMedia className={classes.quoteMedia}
	            	image={getAuthorImg(author.aut_url)}
	            	title={author.aut_nombre} />:""}
			</div>
	   		
	   		
   			
	   		
   			<CardActions className={classes.action}>   				
   				<Hidden smDown>
	   				<div className={classes.actionLeft}>
		   			 	<QuoteLikeButtonContainer quote={quote} />
		   			 	<Tooltip title="Compartir" placement="top" >
		   			 		<Button size="small" variant="outlined" color="default" className={classes.button}
								onClick={this.handleShareExpandClick}>
								<FontAwesomeIcon icon="share" size="lg" />						
							</Button> 
						</Tooltip>					
					</div>

					<div className={classes.actionRight}>
						{ showMoreQuotes ? <Button className={classNames(classes.button,classes.buttonImportant)}
							component={Link} to={getAuthorUrl(author)}
							variant="outlined" size="small" >
								Más Frases de {author.aut_nombre}
						</Button>: ""}
						{ showLinkQuote ? <Button className={classes.button}
							component={Link} to={getQuoteUrl(quote,author)}
							variant="outlined" size="small" color="default" >
								<FontAwesomeIcon icon="angle-right" size="lg" />
						</Button>: ""}	
						
					</div>			
				</Hidden>
				<Hidden mdUp>
	   			 	<QuoteLikeButtonContainer quote={quote} />
	   			 	
	   			 	<Tooltip title="Compartir" placement="top" >
	   			 		<IconButton color="primary" className={classes.button}
							onClick={this.handleShareExpandClick}>
							<FontAwesomeIcon icon="share" />						
						</IconButton> 
					</Tooltip>					
					
					{ showLinkQuote ? <IconButton className={classes.button}
						component={Link} to={getQuoteUrl(quote,author)}
						color="primary" >
							<FontAwesomeIcon icon="angle-right" size="lg" />
					</IconButton>: ""}	
				</Hidden>
			</CardActions>	
			<Collapse in={this.state.shareExpanded} timeout="auto" unmountOnExit>
				<CardContent className={classes.shareContent}>
					<SharePageContainer url={ getShareQuoteUrl(quote,author) } title={quote.frs_frase}
						view="row" />
				</CardContent>
			</Collapse>
		</Card>
	}
}

export default withStyles(styles)(Quote);