import React from 'react';
import Button from '@material-ui/core/Button';
import {  Link } from 'react-router-dom';

import AuthorListContainer from './authorListContainer/AuthorListContainer'

import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
	button: {
    	margin: theme.spacing.unit,
  	},
  	toRight:{
  		textAlign: 'right'
  	}
});

	
function AuthorCol(props) {
	const { classes, ids, view } = props
	if( !ids ) return false
	
	return <div>			
		<AuthorListContainer view={view} ids={ids} />			
		<div className={classes.toRight}>
			<Button color="secondary" className={classes.button} 
				component={Link} to="/autores-frases-bonitas">Más autores</Button>
		</div>
	</div>
}

export default withStyles(styles)(AuthorCol);