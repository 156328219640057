import React, {Component} from 'react';

import { withStyles } from '@material-ui/core/styles'

import { omit }from 'lodash'

import QuoteContainer from '../../quoteContainer/QuoteContainer'

const styles = theme => ({ 
  	quote:{
  		marginBottom: theme.spacing.unit * 4
  	}
});

class QuoteList extends Component {    	
	
	render() {
		const {quotes, classes} = this.props
		if( !quotes ) return false 
		
		const newProps = omit(this.props, 'classes');
		
		return <div>
			{quotes.map( id => <div className={classes.quote} key={id}><QuoteContainer id={id} {...newProps} /></div>)}
		</div>		
	}
}

export default withStyles(styles)(QuoteList);