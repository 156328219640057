
export function getQuoteUrl(quote,author){
	if( !quote || !author ) return false
    return "/frases-de/"+ author.aut_url +"/"+ quote.frs_id
}

export function getAuthorUrl(author){
	if( !author) return false
    return "/frases-de/"+ author.aut_url 
}

export function getTopicUrl(topic){
	if( !topic ) return false
    return "/tema/"+ topic.url 
}

export function getShareQuoteUrl(quote,author){
    return "https://frasede.com" + getQuoteUrl( quote,author )
}

export function getShareAuthorUrl(author){
    return "https://frasede.com/" + getAuthorUrl( author )
}

export function getShareTopicUrl(topic){
    return "https://frasede.com/" + getTopicUrl( topic )
}

export function getTopicImgUrl(topic){
	if( !topic ) return false
    
    return "https://frasede.com/assets/content/topic/"+ topic.url +".jpg"
    //return "/static/topic/"+ topic.url +".jpg"
}
