import React from 'react';


import { withStyles } from '@material-ui/core/styles'

import AuthorLetter from '../authorLetter/AuthorLetter';


const styles = theme => ({
	authorLetter: {
		width: '100%'
	}
});

function AuthorPage(props){
	const {authorLetter, classes } = props
	return <div >{Object.keys(authorLetter).sort((a,b) => {
					if(a < b) return -1
					if(a > b) return 1
					return 0
				}).map( l => <AuthorLetter
					className={classes.authorLetter}
					key={l} 
					letter={l} 
					list={authorLetter[l]} />
				)}
		</div>
}

export default withStyles(styles)(AuthorPage);