import React, {Component} from 'react';
import classnames from 'classnames';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import IconButton from '@material-ui/core/IconButton';

import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography';

import QuoteDoneContainer from '../../quoteDoneContainer/QuoteDoneContainer'
import AnswerContainer from '../../answerContainer/AnswerContainer'

import Alert from '../alert/Alert'


const styles = theme => ({
	gameQuestion: {

	},
	gamePrev: {
		margin: theme.spacing.unit * 2
	},
  	answerBox: {
    	display: 'flex',
    	flexWrap: 'wrap',
    	textAlign: 'center',
    	margin: theme.spacing.unit,
  	},

  	actions: {
    	display: 'flex',
  	},
  	expand: {
    	transform: 'rotate(0deg)',
    	transition: theme.transitions.create('transform', {
      	duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    	[theme.breakpoints.up('sm')]: {
      		marginRight: -8,
    	},
  	},
  	expandOpen: {
    	transform: 'rotate(180deg)',
  	},
});

class Game extends Component {    	
	
	render() {
		const { question, answer, classes,handleClickAnswer,prevStatus,doneOpen,handleDoneOpen,isDoneList } = this.props
		if( !question || !answer ) return false
		
		let snackMessage = ""
		let snackType = ""
		if( prevStatus ){
			if( prevStatus.correct ){
				snackType = "success"
				snackMessage = "Acertaste!! Muy Bien!"
			}else{
				snackType = "error"
				snackMessage = "No es correcto!! El autor es "+ prevStatus.author.aut_nombre
			}
		}

		let quoteDone = ""
		if( isDoneList ){
			quoteDone = <div>
				<CardActions className={classes.actions} disableActionSpacing >
					<IconButton
            			className={classnames(classes.expand, {
              				[classes.expandOpen]: doneOpen,
            				})}
            				onClick={handleDoneOpen}
            				aria-expanded={doneOpen}
            				aria-label="Mostrar resultados"
          				>
            			<ExpandMoreIcon />
          			</IconButton>
				</CardActions>
				<Collapse in={doneOpen} timeout="auto" unmountOnExit>
          			<CardContent>
          				<QuoteDoneContainer />
          			</CardContent>
          		</Collapse>
          	</div>
		}
		return <div>	
			<Card className={classes.gameQuestion}> 
				<CardContent>
					<Typography variant="headline">{question.frs_frase}</Typography>
					<div className={classes.answerBox}>
						{answer.map( id => <AnswerContainer author={id}
							handleClickAnswer={handleClickAnswer} /> )}
					</div>
				</CardContent>
				{quoteDone}
			</Card>
			<Alert 
				type={snackType}
				message={snackMessage}
				open={this.props.snackOpen} 
				handleClose={this.props.handleSnackClose} />
		</div>
	}
}

export default withStyles(styles)(Game);