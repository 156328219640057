
import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import Hidden from '@material-ui/core/Hidden';
import Page2Col from '../../Page2Col'
import Page2ColXs from '../../Page2ColXs'

import { quoteService, topicService } from '../../../services'


import Main from './main/Main'
import Side from './side/Side'

import { loadQuoteList } from '../../../helpers'


class QuoteAuthorContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			topicUrl: "",
			topic: {},
			quoteList: "",
			authorRelated: [],
			topicList: [],
		}

		this.authorRelated = []

	}

	componentWillMount(){
		let topicUrl = this.props.match.params["topicUrl"]
		if( !topicUrl ) {
			topicUrl = this.props["topicUrl"]
		}
		if( !topicUrl ) return false
		
		this.loadData(topicUrl)	

		topicService.load().then( (res) => {			
			this.setState({
				topicList: res.rows
			})	
		})
  	}

	componentWillReceiveProps( nextProps ){	
		const { topicUrl, storeQuote} = nextProps
		
		if( topicUrl && this.state.topicUrl !== topicUrl ){
			if( !storeQuote || !storeQuote.topic  || !storeQuote.topic[topicUrl] ){
				this.loadData(topicUrl)				

				return false
			}
		}
		
		if( storeQuote && storeQuote.topic && this.state.topicUrl && storeQuote.topic ){			
			this.setState({
				quoteList: storeQuote.topic[this.state.topicUrl]
			})
		}
	}

	loadData( topicUrl ){
		const {dispatch} = this.props

		topicService.loadByUrl( topicUrl ).then( res => {
			const topic = res.rows

			quoteService.loadByTopicId( topic.id ).then( res => {
				const quoteList = res.rows

				let newState = update(this.state, {
					topicUrl: {$set: topicUrl},  	
					topic: {$set: topic},  				
  					quoteList: {$set: quoteList.map( r => r.id )}
				})
				this.setState(newState)
				this.setHeaders()
				loadQuoteList( res.rows, '', dispatch )
			})
		})	
	}
	setHeaders() {
		const {topic} = this.state
      	document.title = topic.title
      	document.description = topic.description_short
  	}

  	onQuoteLoad = (quote, author) => {
  		if( this.authorRelated.filter( id => id === author.aut_id ).length === 0 ){
  			this.authorRelated.push( author.aut_id )
  		}
  	}
	
	componentDidUpdate(prevProps) {
		if( this.authorRelated !== this.state.authorRelated){
			this.setState({
				authorRelated: this.authorRelated
			})
		}
	}

	render(){
		const {quoteList,topic,topicList,authorRelated} = this.state
		return <div>
			<Hidden smDown>
				<Page2Col 
					main={<Main quoteList={quoteList} topic={topic} onQuoteLoad={this.onQuoteLoad} />}
					right={<Side topic={topic} topicList={topicList.filter( t => t.id !== topic.id).map(t => t.id)} authorRelated={authorRelated}/>} />
			</Hidden>
			<Hidden mdUp>
				<Page2ColXs 
					main={<Main quoteList={quoteList} topic={topic} onQuoteLoad={this.onQuoteLoad} />}
					right={<Side topic={topic} topicList={topicList.filter( t => t.id !== topic.id).map(t => t.id)} authorRelated={authorRelated}/>} />
			</Hidden>
		</div>
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeTopic: state.topic,     
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteAuthorContainer);
