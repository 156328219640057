import {fetchApi} from '../helpers';

export const authorService = {
	loadById,
    loadByIds,
	loadByUrl,
    loadByUrls,
    loadRandom,
    loadMin,
    loadMinByIds,
    loadFeatured,
};

function loadById( id ) {
    const res  = fetchApi('api/authorLoadById', {
        'id': id
    }) 

    return res
}

function loadByIds( ids ) {
    const res  = fetchApi('api/authorLoadByIds', {
        'ids': ids
    }) 

    return res
}

function loadByUrl( url ) {
    const res  = fetchApi('api/authorLoadByUrl', {
        'url': url
    }) 

    return res
}

function loadByUrls( urls ) {
    const res  = fetchApi('api/authorLoadByUrls', {
        'urls': urls
    }) 

    return res
}

async function loadRandom( ) {
    const res  = fetchApi('api/authorRandom', {}) 
    return res
}

async function loadFeatured( ) {
    const res  = fetchApi('api/authorFeatured', {}) 
    return res
}

function loadMin(){
    const res  = fetchApi('api/authorLoadMin', {}) 
    return res
}

function loadMinByIds( ids ) {
    const res  = fetchApi('api/authorLoadMinByIds', {
        'ids': ids
    }) 
    return res
}