import React, {Component} from 'react';

import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles'

import TopicBoxContainer from '../../topicBoxContainer/TopicBoxContainer';

const styles = theme => ({
  item: {
    height: '100%',
  }
})

class TopicListRow extends Component {    	
	
	render() {
		const { topicList,classes } = this.props
		if( !topicList ) return false

		return <Grid container spacing={16} alignItems="flex-start">{ 
      topicList.map( (t) => <Grid item key={t.id} 
         xs={12} sm={6} md={6} lg={6} xl={3}
         className={classes.item} >
       		<TopicBoxContainer topic={t} /> 
      	</Grid>)}
      </Grid>
	}
}

export default withStyles(styles)(TopicListRow);