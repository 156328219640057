import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import AuthorCol from '../../author/AuthorCol'

import Section from "../../Section"

const styles = theme => ({})

function SideRight(props){    	
	const { authorFeaturedList} = props

	return <Section title="Autores Recomendados"
		content={<AuthorCol view="right" ids={authorFeaturedList} />}
		/>		
}

export default withStyles(styles)(SideRight);