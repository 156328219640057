import React from 'react';
import {  Link } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

import { getAuthorImg } from '../../../../helpers'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	bigAvatar: {
    	width: 50,
    	height: 50,
  	},
});

function Author(props){ 	
	const { author,classes } = props
	if( !author )return false

	return 	<ListItem button component={Link} to={"/frases-de/"+ author.aut_url} >
			<Avatar className={classes.bigAvatar}
				src={getAuthorImg(author.aut_url)}></Avatar>
				<ListItemText primary={author.aut_nombre} secondary={author.country_name} />
		</ListItem>
}

export default withStyles(styles)(Author);