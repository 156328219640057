import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import AuthorInfoContainer from '../../../author/authorInfoContainer/AuthorInfoContainer'
import QuoteListContainer from '../../../quote/quoteListContainer/QuoteListContainer'

const styles = theme => ({
	section:{
		marginBottom: theme.spacing.unit * 8,
	},
	title:{
		marginBottom: theme.spacing.unit * 4,
		color: theme.palette.primary.text,
	},
	sectdion:{
		marginTop: theme.spacing.unit * 8,
		marginBottom: theme.spacing.unit * 2,
	}
})

function Main(props){    	
	const { classes, quoteList, author,getAuthorRandom } = props

	return <div>
		<Hidden smDown>
			<Typography className={classes.title} component="h1" variant="h4" gutterBottom >
				Frases de {author.aut_nombre}</Typography>
		</Hidden>
		<Hidden mdUp className={classes.title}>
			<AuthorInfoContainer 
				authorUrl={author.aut_url} 
				getAuthorRandom={getAuthorRandom} />

			<Typography component="h1" variant="subtitle2" gutterBottom >
				Frases de {author.aut_nombre}:</Typography>	
		</Hidden>
		<QuoteListContainer quotes={quoteList} page="quoteAuthor" />
	</div>
}

export default withStyles(styles)(Main);