import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden';

import AuthorInfoContainer from '../../../author/authorInfoContainer/AuthorInfoContainer'
import RandomButton from '../../../author/RandomButton'

import ShareCol from '../../../ShareCol'
import Section from "../../../Section"

import { getShareAuthorUrl } from '../../../../helpers'


const styles = theme => ({})

function Side(props){    	
	const { author, getAuthorRandom } = props

	return <div>
		<Hidden smDown>
			<Section content={<AuthorInfoContainer 
				authorUrl={author.aut_url} 
				getAuthorRandom={getAuthorRandom} /> } />
		</Hidden>

		<Hidden mdUp>
			<Section content={<RandomButton getAuthorRandom={getAuthorRandom}/>} />
		</Hidden>

		<ShareCol url={ getShareAuthorUrl(author) } 
			title={"Frases de " + author.aut_nombre} />
	</div>
}

export default withStyles(styles)(Side);