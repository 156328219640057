import {fetchApi} from '../helpers';

export const topicService = {
    load,
	loadById,
	loadByUrl,
    loadFeatured,
};


function load( ) {
    const res  = fetchApi('api/topicLoad', {}) 

    return res
}

function loadById( id ) {
    const res  = fetchApi('api/topicLoadById', {
        'id': id
    }) 

    return res
}

function loadByUrl( url ) {
    const res  = fetchApi('api/topicLoadByUrl', {
        'url': url
    }) 

    return res
}

function loadFeatured( ) {
    const res  = fetchApi('api/topicLoadFeatured', {
    }) 

    return res
}