import React from 'react';
import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid';

import MenuContainer from './menuContainer/MenuContainer';

const styles = theme => ({
	container: {
		display: "flex", 
		alignItems: "flex-start",
	},	
	colContainer: {
  		padding: theme.spacing.unit * 4,
	},
	left: {  		
  		backgroundColor: theme.palette.background.white,
  		boxShadow:  theme.shadows[3],
  	},
  	main: {
  		minHeight: '100%',
  		backgroundColor: theme.palette.primary.light,  		
  	},
  	right: {
	  	backgroundColor: theme.palette.background.white,
		boxShadow: theme.shadows[1],
  	},  	
});

const Page2ColXs = (props) => {
	const {classes} = props
	let sideRight = ""
	let sideLeft = ""
	if( props.right){
		sideRight = <Grid item xs={12} sm={4} className={classes.right}>
			<div className={classes.colContainer}>
				{props.right}
			</div>
		</Grid>
	}else if( props.left ){
		sideLeft = <Grid item xs={12} sm={4} className={classes.left}>
			<div className={classes.colContainer}>
				{props.left}
			</div>
		</Grid>
	}
	
	return <div>
		<MenuContainer />
		<Grid container className={classes.container} justify="center">		
			
			{sideLeft}

			<Grid item xs={12} sm={8} className={classes.main}>
				<div className={classes.colContainer}>
					{props.main}			
				</div>
			</Grid>

			{sideRight}
		</Grid>
	</div>    
}

export default withStyles(styles)(Page2ColXs)