
import React, {Component} from 'react';
import { connect } from 'react-redux'

import Page3Col from '../Page3Col'


import { loadQuoteList } from '../../helpers'

import { quoteService, authorService, topicService } from '../../services'

import CoverMain from './coverMain/CoverMain';
import SideLeft from './sideLeft/SideLeft';
import SideRight from './sideRight/SideRight';

class CoverContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			quoteList: "",
			authorFeaturedList: [],
			topicList: [],
		}
	}

	componentWillMount(){
		const {dispatch} = this.props

		quoteService.loadByPag( 1 ).then( (res) => {
			this.setState({
				quoteList: res.rows.result.map( r => r.id )
			})	
			loadQuoteList( res.rows.result, '', dispatch )		
		})

		authorService.loadFeatured().then( (res) => {
			this.setState({
				authorFeaturedList: res.rows.map( r => r.aut_id )
			})	
		})

		topicService.load().then( (res) => {			
			this.setState({
				topicList: res.rows
			})	
		})
  	}	

	componentDidMount() {
      	document.title = "Frase De - La sabiduria en miles de frases"
      	document.description = "Frases bonitas, frases de amor, frases para reflexionar... Todo tipo de frases para enriquecer la vida"
  	}

	render(){
		const {quoteList, authorFeaturedList, topicList} = this.state

		return <Page3Col 			
			left={<SideLeft topicList={topicList} />}
			main={<CoverMain quoteList={quoteList} />}
			right={<SideRight authorFeaturedList={authorFeaturedList} />} />
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		quote: state.quote,     
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CoverContainer);
