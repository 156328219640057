import React, {Component} from 'react';

import { getAuthorImg } from '../../../../helpers'

import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	bigAvatar: {
    	width: 50,
    	height: 50,
  	},
  	chip: {
  		height: '50px',
  		lineHeight: '50px',
  		borderRadius: '25px',
    	margin: theme.spacing.unit ,
  	}  
});

class Answer extends Component {    	
	
	render() {
		const { classes, author, handleClickAnswer } = this.props
		if( !author ) return false
			
		return <Chip
			key={author.aut_id}
			avatar={<Avatar alt={author.aut_nombre} className={classes.bigAvatar} src={getAuthorImg(author.aut_url)} />}
			label={author.aut_nombre}
			onClick={(id) => handleClickAnswer(author.aut_id)}
			className={classes.chip} />
	}
}

export default withStyles(styles)(Answer);