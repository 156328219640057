import {fetchApi} from '../helpers';

export const quoteService = {
	loadById,
    loadByIds,
	loadByPag,
    loadByAuthorId,
    loadByAuthorUrl,
    loadByTopicId,
    loadGame,
    addLike,
    delLike,
};

function loadById( id ) {
    const res  = fetchApi('api/quoteLoadById', {
        'id': id
    }) 

    return res
}

function loadByIds( ids ) {
    const res  = fetchApi('api/quoteLoadByIds', {
        'ids': ids
    }) 

    return res
}

function loadByPag( pag ) {
    const res  = fetchApi('api/quoteLoadByPag', {
        'pag': pag
    }) 

    return res
}

function loadByAuthorId( authorId ) {
    const res  = fetchApi('api/quoteLoadByAuthorId', {
        'id': authorId
    }) 

    return res
}

function loadByAuthorUrl( authorUrl ) {
    const res  = fetchApi('api/quoteLoadByAuthorUrl', {
        'url': authorUrl
    }) 

    return res
}

function loadByTopicId( id ) {
    const res  = fetchApi('api/quoteLoadByTopicId', {
        'id': id
    }) 

    return res
}

function loadGame( ) {
    const res  = fetchApi('api/quoteLoadGame', {}) 
    return res
}

function addLike( quoteId ) {
    const res  = fetchApi('api/quoteAddLike', {
        'quoteId': quoteId
    }) 

    return res
}
function delLike( quoteId ) {
    const res  = fetchApi('api/quoteDelLike', {
        'quoteId': quoteId
    }) 

    return res
}