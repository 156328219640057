import React from 'react';
import Button from '@material-ui/core/Button';
import {  Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
	button: {
   		margin: theme.spacing.unit,
   		backgroundColor: theme.palette.secondary.dark,
   		color: theme.palette.secondary.textDark
  	},
});

	
function RandomButton(props) {
	const {classes, getAuthorRandom } = props

	let randomButton = ""
	if( props.getAuthorRandom ){
		randomButton = <Button 
			variant="contained" 
			color="primary" 
			className={classes.button}
			onClick={getAuthorRandom}>
    			Probar un autor al Azar
  		</Button> 
	}else{
		randomButton = <Button 
			variant="contained" 
			color="primary" 
			className={classes.button}
			component={Link}
			to={"/autor-aleatorio"}>
    			Probar un autor al Azar
  		</Button> 
	}

	return randomButton
}

export default withStyles(styles)(RandomButton);