import React, {Component} from 'react';

import green from '@material-ui/core/colors/green';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { withStyles } from '@material-ui/core/styles'

import classNames from 'classnames';

import QuoteLikeButtonContainer from '../../../quote/quoteLikeButtonContainer/QuoteLikeButtonContainer'

const styles = theme => ({
	avatarCorrect: {
	    backgroundColor: green[600],
	},
	avatarError: {
		backgroundColor: theme.palette.error.dark,
	},
	answerFailed: {
		textDecoration: "line-through",
		paddingLeft:	theme.spacing.unit,
	}
});

class QuoteDone extends Component {    	
	
	render() {
		const { result, classes } = this.props
		if( !result )return false
		let avatarClass = "avatarCorrect"
		let avatarIcon = <CheckCircleIcon />
		let answer = <div>{result.author.aut_nombre}</div>
		if(!result.correct){
			avatarClass = "avatarError"
			avatarIcon = <ErrorIcon/>
			answer = <span>
				<span>{result.author.aut_nombre}</span>
				<span className={classNames(classes.answerFailed)}>{result.answer.aut_nombre}</span></span>
		}

		return <ListItem key={result.question.frs_id} >
			<Avatar className={classes[avatarClass]} button component="a"
				href={"/frases-de/" + result.author.aut_url }>
				{avatarIcon}
			</Avatar>
			<ListItemText  button component="a" href={"/frases-de/" + result.author.aut_url } primary={result.question.frs_frase} secondary={answer} />
			<QuoteLikeButtonContainer quote={result.question} />
		</ListItem>	
	}
}

export default withStyles(styles)(QuoteDone);