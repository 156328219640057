import React, {Component} from 'react';

import List from '@material-ui/core/List';

import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import QuoteDone from '../quoteDone/QuoteDone';


import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
	progressText: {
		textAlign: 'center',
		margin: theme.spacing.unit 
	}
});

class QuoteDoneList extends Component {    	
	
	render() {
		const { quoteDoneList, classes } = this.props
		if( !quoteDoneList ) return false 

		const normalise = (value, total) => (value - 0) * 100 / (total - 0)

		const total = quoteDoneList.length
		const correct = quoteDoneList.filter( s => s.status.correct ).length
		const progress = normalise(correct,total)

		let alertToPlay = ""
		if( total === 0 ){
			alertToPlay = <Typography component="caption" color="primary">Aún no has jugado! Adivina de quien es cada frase.<br/>
			Podras consultar las frases respondidas aquí.</Typography>
		}
		return <div>
			<Typography component="h3" variant="h6">Resultados:</Typography>
			{alertToPlay}		
			{ total > 0 ? <div className={classes.progress} >
				<LinearProgress variant="determinate" color="primary" value={progress} />
				<div className={classes.progressText}>
					<Typography variant="caption">{correct} aciertos! / {total} preguntas</Typography>
				</div>
			</div>:""}
			

			<List>						
          		{quoteDoneList.reverse().map( s =>  <QuoteDone result={s.status} key={s.id} />)}
        	</List>
        </div>
	}
}

export default withStyles(styles)(QuoteDoneList);