import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';

import GameContainer from '../../gameContainer/GameContainer'
import TopicListContainer from '../../../topic/topicListContainer/TopicListContainer'
import AuthorCol from '../../../author/AuthorCol'

const styles = theme => ({
	section:{
		marginBottom: theme.spacing.unit * 8,
	},
	title:{
		marginBottom: theme.spacing.unit * 4,
		color: theme.palette.primary.text
	},
	sectdion:{
		marginTop: theme.spacing.unit * 8,
		marginBottom: theme.spacing.unit * 2,
	}
})

function Main(props){    	
	const { classes, topicList } = props

	return <div>
		<div className={classes.section}>
			<Typography className={classes.title} component="h1" variant="h4" gutterBottom >¿Quién dijo esta frase?</Typography>
			<GameContainer doneOpen={false} isDoneList={false} />
		</div>
		<div>
			<div style={{display: 'flex'}}>
				<TopicListContainer view="grid" ids={topicList.filter( t => t.featured === 0).map(t => t.id)} />
			</div>					
			<AuthorCol />
		</div>
	</div>

}

export default withStyles(styles)(Main);