
import React, {Component} from 'react';
import { connect } from 'react-redux'

import TopicList from './topicList/TopicList';
import TopicListRow from './topicList/TopicListRow';
import TopicGrid from './topicGrid/TopicGrid';

import { topicActions } from '../../../actions'

class TopicListContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			idList: [],
			topicList: ""
		}
	}

	componentWillMount(){
		this.setState({
			idList: this.props.ids
		})

		this.componentWillReceiveProps(this.props)
  	}

	componentWillReceiveProps( nextProps ){
		const {dispatch,storeTopic} = nextProps
		if( !nextProps.ids ) return false
		if( storeTopic && storeTopic.isFetching ) return false
		this.setState({
			idList: nextProps.ids,
		})

		if( !storeTopic || !storeTopic.list ){			
			dispatch( topicActions.load() )			
			return false
		}
		let topicList = []

		nextProps.ids.map( id => {
			const t = storeTopic.list.filter( t => t.id === id)
			topicList.push(t[0])
			return true
		})

		this.setState({
			idList: nextProps.ids,
			topicList: topicList
		})
	}
	
	render(){
		const {topicList} = this.state
		if ( !topicList ) return false

		let topicView = <TopicList list={topicList} />

		
		if( this.props.view ){
			if( this.props.view === "row" ){
				topicView = <TopicListRow topicList={topicList}/>
			}else if( this.props.view === "grid"){
				topicView = <TopicGrid topicList={topicList} />
			}
		}
		
		return topicView

	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeTopic: state.topic,
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicListContainer);
