import React, {Component} from 'react';
import { connect } from 'react-redux'

import TopicBox from './topicBox/TopicBox';


class TopicBoxContainer extends Component { 
	
	render(){
		const {topic} = this.props
		if ( !topic ) return false

		return <TopicBox topic={topic}/>
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeTopic: state.topic,
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicBoxContainer);
