
import React, {Component} from 'react';
import { connect } from 'react-redux'

import AuthorList from './authorList/AuthorList';
import AuthorListRight from './authorListRight/AuthorListRight';

import { loadAuthorList } from '../../../helpers'


class AuthorListContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			idList: "",
			authorList: ""
		}
	}

	componentWillMount(){
		this.componentWillReceiveProps(this.props)
  	}

	componentWillReceiveProps( nextProps ){
		const {dispatch,storeAuthor} = nextProps
		if( storeAuthor && storeAuthor.isFetching ) return false
		if( JSON.stringify(this.state.idList) !== JSON.stringify(nextProps.ids) ){
			this.setState({
				idList: nextProps.ids,
				authorList: nextProps.ids
			})
			loadAuthorList( nextProps.ids, storeAuthor, dispatch )
		}else{
			this.setState({
				idList: this.props.ids
			})
		}
	}
	
	render(){
		const {authorList} = this.state
		if ( !authorList ) return false
		
		let view = <AuthorList authorList={authorList} />
		
		if( this.props.view && this.props.view === "rdight"){
			view = <AuthorListRight authorList={authorList} />
		}

		return view
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeAuthor: state.author,
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorListContainer);
