import {
  TOPIC_LOAD_REQUEST,
  TOPIC_LOAD_SUCCESS,
  TOPIC_LOAD_FAILURE,
} from '../actions/topic'

import update from 'immutability-helper';


const topic = (state = [], action) => {
	let newState = ""
	switch (action.type) {
		case TOPIC_LOAD_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case TOPIC_LOAD_SUCCESS:			
			let stateList = Object.assign( {}, state.list )
			stateList =   action.rows
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isFetching: {$set: false},
				list: {$set: stateList},				
			});

			return newState 

		case TOPIC_LOAD_FAILURE:
			return {};


		default:
			return state
  	}
}

export default topic