
import React, {Component} from 'react';
import { connect } from 'react-redux'

import Page2Col from '../../Page2Col'
import Page2ColXs from '../../Page2ColXs'

import Hidden from '@material-ui/core/Hidden';

import Main from './main/Main'
import Side from './side/Side'

import { loadQuoteList } from '../../../helpers'

import { quoteService, topicService } from '../../../services'

class QuoteLikeContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			quoteList: "",
			topicList: [],
		}

	}


	componentWillMount(){	
		this.componentWillReceiveProps(this.props)
		
		topicService.load().then( (res) => {			
			this.setState({
				topicList: res.rows
			})	
		})

	}

	componentWillReceiveProps( nextProps ){	
		const {dispatch,storeQuote} = nextProps

		if( storeQuote && storeQuote.isFetching) return false

		if( storeQuote.like ){
			quoteService.loadByIds(storeQuote.like).then( l => 
				loadQuoteList( l.rows.map( q => {
					return {
						id: q.frs_id,
						autor_id: q.autor_id
					} 
				}), storeQuote, dispatch )		
			)
			
			this.setState({
				quoteList: storeQuote.like
			})
		}
	}
	
	render(){
		const {quoteList,topicList} = this.state

		return <div>
			<Hidden smDown>
				<Page2Col 
					main={<Main quoteList={quoteList} />}
					right={<Side topicList={topicList} />} />
			</Hidden>
			<Hidden mdUp>
				<Page2ColXs
					main={<Main quoteList={quoteList}  />}
					right={<Side topicList={topicList} />} />
			</Hidden>
		</div>	
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeQuote: state.quote,     
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteLikeContainer);
