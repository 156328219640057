
import React, {Component} from 'react';
import { connect } from 'react-redux'

import Author from './author/Author';
import AuthorRight from './authorRight/AuthorRight';


class AuthorContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			id: "",
			author: ""
		}
	}
	
	setAuthor( dispatch, storeAuthor, id ){
		if( !id ) return false;
		
		if( storeAuthor 
			&& storeAuthor.isFetching) return false

		if( !storeAuthor 
			|| !storeAuthor.list 
			|| !storeAuthor.list[id]) return false	
	

		const a = storeAuthor.list[id]

		this.setState({
			author: a
		})

		return true
	}

	componentWillMount(){		
		this.componentWillReceiveProps(this.props)	
  	}

	componentWillReceiveProps( nextProps ){
		const {dispatch,storeAuthor,id, author} = nextProps
		if( author ){
			this.setState({
				id: author.aut_id,
				author: author,
			})
		}else{
			if( !this.state.id ){
				this.setState({
					id: id
				})
				this.setAuthor( dispatch, storeAuthor, id )

				return false
			}else{
				if( !storeAuthor ) return false;
				if( storeAuthor.isFetching ) return false;
				this.setAuthor( dispatch, storeAuthor, this.state.id )
			}
		}
	}
	

	render(){
		const {author} = this.state
		if ( !author ) return false

		let view = <Author 
			author={author}
			page={this.props.page} />
		
		if( this.props.view && this.props.view === "right"){
			view = <AuthorRight
				author={author}
				page={this.props.page} />
		}
		
		return view
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeAuthor: state.author,     
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorContainer);
