
import React, {Component} from 'react';
import { connect } from 'react-redux'


import Answer from './answer/Answer';


class AnswerContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {	
			id: "",
			author: {}
		}
	}

	componentWillMount( ){		
		this.componentWillReceiveProps(this.props)
	}

	componentWillReceiveProps( nextProps ){
		const { storeAuthor } = nextProps
		if( !storeAuthor || !storeAuthor.min ) return false
		if( storeAuthor && storeAuthor.isFetching ) return false

		const author =  storeAuthor.min[nextProps.author]
		
		this.setState({
			author: author
		})

	}

	render(){
		const {author}  = this.state
		return <Answer author={author}
			handleClickAnswer={this.props.handleClickAnswer} />
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
  		storeAuthor: state.author,
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AnswerContainer);
