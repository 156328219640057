
import React, {Component} from 'react';
import { connect } from 'react-redux'

import Page2Col from '../../Page2Col'
import Page2ColXs from '../../Page2ColXs'

import Hidden from '@material-ui/core/Hidden';

import AuthorPage from './authorPage/AuthorPage';
import TopicListContainer from '../../topic/topicListContainer/TopicListContainer'


import { loadAuthorMinList } from '../../../helpers'
import { topicService } from '../../../services'

import Section from "../../Section"


class AuthorPageContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			authorLetter: {},
			topicList: []
		}
	}

	componentWillMount(){
		this.loadData()
  	}

  	componentWillReceiveProps( nextProps ){
  		this.loadData()
  	}
	
	loadData( ){
		const {dispatch,storeAuthor} = this.props

		if( !storeAuthor || !storeAuthor.min ){
			loadAuthorMinList( "",storeAuthor,dispatch)
			return false
		}

  		if( storeAuthor.min ){
			let authorLetter = {}
			Object.keys(storeAuthor.min).map( i => storeAuthor.min[i]).map( a => {
				let letter = a.aut_url[0]
				if( !authorLetter[letter]){
					authorLetter[letter] = []
				}
				authorLetter[letter].push( a )
				return true
			})

			this.setState({
				authorLetter: authorLetter
			})
		}

		topicService.load().then( res => {
			this.setState({
				topicList: res.rows
			}) 
		})	
	}

	render(){
		const {authorLetter,topicList} = this.state
		let topicGrid = <Section title="Temas"
			content={<TopicListContainer view="grid" ids={topicList.map( t => t.id)} />}
		/>
		return <div>
			<Hidden smDown>
				<Page2Col 
					left={topicGrid}
					main={<AuthorPage authorLetter={authorLetter} />} />
			</Hidden>
			<Hidden mdUp>
				<Page2ColXs
					left={topicGrid}
					main={<AuthorPage authorLetter={authorLetter} />} />
			</Hidden>
		</div>
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeAuthor: state.author,
		storeQuote: state.quote,
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorPageContainer);
