import React from 'react';

import List from '@material-ui/core/List';

import { withStyles } from '@material-ui/core/styles'

import AuthorContainer from '../../authorContainer/AuthorContainer';


const styles = theme => ({})

function AuthorListRight(props){    		
	const { authorList } = props
	if( !authorList ) return false

	return <List component="nav">
		{authorList.map( id => <AuthorContainer id={id} key={id} view="right" />)}
	</List>
}

export default withStyles(styles)(AuthorListRight);