import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom';

import CoverContainer from '../components/coverContainer/CoverContainer';
import QuoteAuthorContainer from '../components/quote/quoteAuthorContainer/QuoteAuthorContainer';
import QuotePageContainer from '../components/quote/quotePageContainer/QuotePageContainer';
import QuoteTopicContainer from '../components/quote/quoteTopicContainer/QuoteTopicContainer';
import QuoteLikeContainer from '../components/quote/quoteLikeContainer/QuoteLikeContainer';

import GamePageContainer from '../components/game/gamePageContainer/GamePageContainer';
import AuthorPageContainer from '../components/author/authorPageContainer/AuthorPageContainer';

import { quoteActions } from '../actions'

import RandomQuoteAuthorContainer from '../components/quote/randomQuoteAuthorContainer/RandomQuoteAuthorContainer';
import { withCookies } from 'react-cookie';

import ReactGA from 'react-ga';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from '../themes/main';

import 'typeface-roboto'

//import { history } from '../helpers/history';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHeart, faShare,faAngleRight } from '@fortawesome/free-solid-svg-icons'

library.add( faHeart, faShare, faAngleRight)

ReactGA.initialize('UA-25653552-3');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component { 

  	constructor(props) {
   	super(props);

   	this.state = {
			cookieRead: false
		}


    	/*const { dispatch } = this.props;
    		history.listen((location, action) => {
      	// clear alert on location change
       	dispatch(alertActions.clear());
    	});*/
   }

   componentWillMount( ){
   	this.componentWillReceiveProps(this.props)
   }

   componentWillReceiveProps(nextProps){
   	const {cookies,dispatch} = nextProps
   	if( !this.state.cookieRead && cookies ){
   		let likeList = cookies.get('likeList') || []
   		dispatch( quoteActions.cookieGetLike( likeList ) )
   		this.setState({
   			cookieRead: true
   		})
   	}
   }
   render(){
      return <MuiThemeProvider theme={theme}>
        	<Switch>
				    <Route exact path="/" component={CoverContainer} />
         	  <Route exact path="/frases-de/:authorUrl" component={QuoteAuthorContainer} />
         	  <Route exact path="/frases-de/:authorUrl/:quoteId" component={QuotePageContainer} />
         	  <Route exact path="/tema/:topicUrl" component={QuoteTopicContainer} />
         	  <Route exact path="/frases-preferidas/" component={QuoteLikeContainer}  />
         	  <Route exact path="/autor-aleatorio/" component={RandomQuoteAuthorContainer}  />
         	  <Route exact path="/juego-frases/" component={GamePageContainer}  />
         	  <Route exact path="/autores-frases-bonitas/" component={AuthorPageContainer}  />
			   </Switch>
		  </MuiThemeProvider>
   }
}

const mapStateToProps = (state, ownProps) => {}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default withRouter(withCookies(connect(mapStateToProps, mapDispatchToProps)(App)));  