import { topicService } from '../services';
import { alertActions } from './';


export const topicActions = {
    load,
    loadById,
    loadByPag,
    loadByAuthor,
}

export const TOPIC_LOAD_REQUEST = 'TOPIC_LOAD_REQUEST'
export const TOPIC_LOAD_SUCCESS = 'TOPIC_LOAD_SUCCESS'
export const TOPIC_LOAD_FAILURE = 'TOPIC_LOAD_FAILURE'

function load( ) {
    return dispatch => {
        dispatch(request( ));   
        topicService.load( )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: TOPIC_LOAD_REQUEST } 
    }
    function success(rows) { return { type: TOPIC_LOAD_SUCCESS, rows } }
    function failure(error) { return { type: TOPIC_LOAD_FAILURE, error } }
}

export const TOPIC_LOAD_ID_REQUEST = 'TOPIC_LOAD_ID_REQUEST'
export const TOPIC_LOAD_ID_SUCCESS = 'TOPIC_LOAD_ID_SUCCESS'
export const TOPIC_LOAD_ID_FAILURE = 'TOPIC_LOAD_ID_FAILURE'

function loadById( id ) {
    return dispatch => {
        dispatch(request( ));   
        topicService.loadById( id )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: TOPIC_LOAD_ID_REQUEST } 
    }
    function success(rows) { return { type: TOPIC_LOAD_ID_SUCCESS, rows } }
    function failure(error) { return { type: TOPIC_LOAD_ID_FAILURE, error } }
}

export const TOPIC_LOAD_PAG_REQUEST = 'TOPIC_LOAD_PAG_REQUEST'
export const TOPIC_LOAD_PAG_SUCCESS = 'TOPIC_LOAD_PAG_SUCCESS'
export const TOPIC_LOAD_PAG_FAILURE = 'TOPIC_LOAD_PAG_FAILURE'

function loadByPag( iPag ) {
    return dispatch => {
        dispatch(request( ));   
        topicService.loadByPag( iPag )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: TOPIC_LOAD_PAG_REQUEST } 
    }
    function success(rows) { return { type: TOPIC_LOAD_PAG_SUCCESS, rows } }
    function failure(error) { return { type: TOPIC_LOAD_PAG_FAILURE, error } }
}

export const TOPIC_LOAD_AUTHOR_REQUEST = 'TOPIC_LOAD_AUTHOR_REQUEST'
export const TOPIC_LOAD_AUTHOR_SUCCESS = 'TOPIC_LOAD_AUTHOR_SUCCESS'
export const TOPIC_LOAD_AUTHOR_FAILURE = 'TOPIC_LOAD_AUTHOR_FAILURE'

function loadByAuthor( sAuthor ) {
    return dispatch => {
        dispatch(request( sAuthor ));   
        topicService.loadByAuthor( sAuthor )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: TOPIC_LOAD_AUTHOR_REQUEST } 
    }
    function success(rows) { return { type: TOPIC_LOAD_AUTHOR_SUCCESS, rows } }
    function failure(error) { return { type: TOPIC_LOAD_AUTHOR_FAILURE, error } }
}