import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button';

import Tooltip from '@material-ui/core/Tooltip'
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styles = theme => ({
  	button: {
  		margin: theme.spacing.unit
  	},
  	container: {
  		display: 'inline-block'
  	}
})

function QuoteLikeButton(props) {    	

	const { quote, classes, handleLikeClick, isLike } = props
	if( !quote )return false		
	
	let voteString = ""
	if( quote.frs_votosok === 1 ){
		voteString = quote.frs_votosok +" Voto";
	}else if( quote.frs_votosok > 1){
		voteString = quote.frs_votosok +" Votos";
	}

	const colorVoteButton = (isLike)?"secondary":"default";
	const colorVoteButtonXs = (isLike)?"secondary":"primary";

	const variantVoteButton = (isLike)?"contained":"outlined";

	const voteButton = <Button size="small" variant={variantVoteButton} 
			color={colorVoteButton} className={classes.button}
			onClick={handleLikeClick} >
			<FontAwesomeIcon icon="heart" size="lg" />
		</Button>
	const voteButtonXs = <IconButton
			color={colorVoteButtonXs} className={classes.button}
			onClick={handleLikeClick} >
			<FontAwesomeIcon icon="heart" />
		</IconButton>
	
	let vote = voteButton
	if( voteString ){
		vote = <Tooltip title={voteString} placement="top">{voteButton}</Tooltip>
	}

	return <div className={classes.container}>
		<Hidden smDown>
			{  vote }
		</Hidden>
		<Hidden mdUp>
			{ voteButtonXs }
		</Hidden>
	</div>
}

export default withStyles(styles)(QuoteLikeButton);