import React, {Component} from 'react'
import { connect } from 'react-redux'

import QuoteLikeButton from './quoteLikeButton/QuoteLikeButton'
import { withCookies } from 'react-cookie'

import { quoteActions } from '../../../actions'

class QuoteLikeButtonContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			id: "",
			quote: {},
			isLike: false,
			likeList: [],
		}
	}

	componentWillMount(){		
		if( !this.props.quote) return false

		const id = this.props.quote.frs_id

		this.setState({
			id: id,
			quote: this.props.quote,
		})

		this.componentWillReceiveProps( this.props )	

  	}

	componentWillReceiveProps( nextProps ){
		const {storeQuote,quote} = nextProps		

		if( !quote ) return false
		if( !storeQuote ) return false
		if( storeQuote.isFetching ) return false

		let isLike = false
		if( storeQuote.like){
			storeQuote.like.filter( like_id => like_id === this.state.id  ).map( id => {
				isLike = true
				return true
			})
			
			if( JSON.stringify(this.state.likeList) !== JSON.stringify(storeQuote.like) ){
				nextProps.cookies.set('likeList', storeQuote.like, { path: '/' });			
			} 
		}

		this.setState({
			isLike: isLike,
			likeList: storeQuote.like
		})
	}
	
	handleLikeClick = () => {
		const {dispatch} = this.props

		if( !this.state.isLike ){
			dispatch( quoteActions.addLike( this.state.id ) )
		}else{
			dispatch( quoteActions.delLike( this.state.id ) )
		}
	}

	render(){
		const {quote,isLike} = this.state
		if ( !quote ) return false

		return <QuoteLikeButton 
			quote={quote}
			isLike={isLike}
			handleLikeClick={this.handleLikeClick}
			view={this.props.view} />
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		storeQuote: state.quote,
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(QuoteLikeButtonContainer));
