import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import ShareCol from '../../../ShareCol'

const styles = theme => ({})

function Side(props){    	
	const { url, title } = props

	return <ShareCol url={ url } 
			title={title} />
}

export default withStyles(styles)(Side);