import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
	section:{
		marginBottom: theme.spacing.unit * 8,
	},
	title:{
		marginBottom: theme.spacing.unit * 4,
	},
})

function Section(props){    	
	const { classes, title, content } = props

	return <div className={classes.section}>
		<Typography component="h3" variant="h6">{title}</Typography>
		{content}
	</div>
}

export default withStyles(styles)(Section);