import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import ShareCol from '../../../ShareCol'

import TopicListContainer from '../../../topic/topicListContainer/TopicListContainer'
import AuthorCol from '../../../author/AuthorCol'
import Section from "../../../Section"

import { getShareTopicUrl } from '../../../../helpers'


const styles = theme => ({})

function Main(props){    	
	const { topic, topicList,authorRelated } = props
	return <div>
		<Section title={"Autores de "+ topic.title}
			content ={<AuthorCol view="right" ids={authorRelated} />} />
		
		<Section title="Temas"
			content ={<TopicListContainer view="grid" ids={topicList} />} />

		<ShareCol url={ getShareTopicUrl(topic) } title={"Frases de " + topic} />
	</div>
}

export default withStyles(styles)(Main);