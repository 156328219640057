
import React, {Component} from 'react';
import { connect } from 'react-redux'



import Page2Col from '../../Page2Col'

import Main from './main/Main'
import Side from './side/Side'
import { topicService } from '../../../services'


class GamePageContainer extends Component { 

	constructor(props) {
		super(props);

		this.state = {
			topicList: [],
		}
	}

	setHeaders() {
      	document.title = "Juego de Frases"
      	document.description = "Quién dijo esta frase? Una forma divertida de aprender, y conocer personajes célebres"
  	}

	componentWillMount(){
		topicService.load().then( (res) => {
			this.setState({
				topicList: res.rows
			})	
		})
  	}

  	componentDidMount(){
		this.setHeaders()
  	}

	render(){
		const {topicList} = this.state
		if( !topicList ) return false
		return <Page2Col 
			main={<Main topicList={topicList} />}
			right={<Side topicList={topicList} />}
		/>
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GamePageContainer);
