import {  
  QUOTE_LOAD_ID_REQUEST,
  QUOTE_LOAD_ID_SUCCESS,
  QUOTE_LOAD_ID_FAILURE,
  QUOTE_LOAD_IDS_REQUEST,
  QUOTE_LOAD_IDS_SUCCESS,
  QUOTE_LOAD_IDS_FAILURE,
  QUOTE_LOAD_PAG_REQUEST,
  QUOTE_LOAD_PAG_SUCCESS,
  QUOTE_LOAD_PAG_FAILURE,
  QUOTE_LOAD_AUTHOR_REQUEST,
  QUOTE_LOAD_AUTHOR_SUCCESS,
  QUOTE_LOAD_AUTHOR_FAILURE,
  QUOTE_COOKIE_GET_LIKE_REQUEST,
  QUOTE_COOKIE_GET_LIKE_SUCCESS,
  QUOTE_COOKIE_GET_LIKE_FAILURE,
  QUOTE_ADD_LIKE_REQUEST,
  QUOTE_ADD_LIKE_SUCCESS,
  QUOTE_ADD_LIKE_FAILURE,
  QUOTE_DEL_LIKE_REQUEST,
  QUOTE_DEL_LIKE_SUCCESS,
  QUOTE_DEL_LIKE_FAILURE,
  QUOTE_ADD_GAME_DONE_REQUEST,
  QUOTE_ADD_GAME_DONE_SUCCESS,
  QUOTE_ADD_GAME_DONE_FAILURE,
} from '../actions/quote'

import update from 'immutability-helper';


const quote = (state = [], action) => {
	let newState = {}
	let stateList = Object.assign( {}, state.list )

	switch (action.type) {
		case QUOTE_LOAD_ID_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case QUOTE_LOAD_ID_SUCCESS:			
			action.rows.map( (r) => {
				stateList[r.frs_id] =   r	
				return true
			})
			

			newState = Object.assign( {}, state )
			newState = update( newState, {
				isFetching: {$set: false},
				list: {$set: stateList},				
			});

			return newState 

		case QUOTE_LOAD_ID_FAILURE:
			return {};

		case QUOTE_LOAD_PAG_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case QUOTE_LOAD_PAG_SUCCESS:
			let quotePag = []
			action.rows.result.map((q) => {
				quotePag.push(q.id)
				return true;
			})

			let statePag = Object.assign( {}, state.pag )
			statePag[action.rows.pag] = quotePag

			newState = Object.assign( {}, state )
			newState = update( newState, {
				isFetching: {$set: false},
				pag: {$set: statePag},				
			});

			return newState 

		case QUOTE_LOAD_PAG_FAILURE:
			return {};


		case QUOTE_LOAD_AUTHOR_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case QUOTE_LOAD_AUTHOR_SUCCESS:
			let quoteAuthor = []
			action.rows.result.map((q) => {
				quoteAuthor.push(q.id)
				return true;
			})

			let stateAuthor = Object.assign( {}, state.author )
			stateAuthor[action.rows.authorUrl] = quoteAuthor

			newState = Object.assign( {}, state )
			newState = update( newState, {
				isFetching: {$set: false},
				author: {$set: stateAuthor},				
			});

			return newState 

		case QUOTE_LOAD_AUTHOR_FAILURE:
			return {};

		case QUOTE_LOAD_IDS_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case QUOTE_LOAD_IDS_SUCCESS:			
			action.rows.map( (r) => {
				stateList[r.frs_id] =   r	
				return true
			})
			

			newState = Object.assign( {}, state )
			newState = update( newState, {
				isFetching: {$set: false},
				list: {$set: stateList},				
			});

			return newState 

		case QUOTE_LOAD_IDS_FAILURE:
			return {};

		case QUOTE_COOKIE_GET_LIKE_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case QUOTE_COOKIE_GET_LIKE_SUCCESS:
			newState = Object.assign( {}, state )
			const like  = Object.assign( [], action.rows )
			newState = update( newState, {
				isFetching: {$set: false},
				like: {$set: like},				
			});

			return newState 

		case QUOTE_COOKIE_GET_LIKE_FAILURE:
			return {};

		case QUOTE_ADD_LIKE_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case QUOTE_ADD_LIKE_SUCCESS:
			newState = Object.assign( {}, state )
			newState.like = Object.assign( [], state.like )
			newState = update( newState, {
				isFetching: {$set: false},
				like: {$push: [action.rows.id]},				
			});

			return newState 

		case QUOTE_ADD_LIKE_FAILURE:
			return {};

		case QUOTE_DEL_LIKE_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case QUOTE_DEL_LIKE_SUCCESS:
			newState = Object.assign( {}, state )
			newState.like = Object.assign( [], state.like )
			newState = update( newState, {
				isFetching: {$set: false},
				like: like => like.filter(item => item !== action.rows.id),
			});

			return newState 

		case QUOTE_DEL_LIKE_FAILURE:
			return {};

		case QUOTE_ADD_GAME_DONE_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case QUOTE_ADD_GAME_DONE_SUCCESS:
			newState = Object.assign( {}, state )
			newState.done = Object.assign( [], state.done )
			newState = update( newState, {
				isFetching: {$set: false},
				done: {$push: [action.rows]},				
			});

			return newState 

		case QUOTE_ADD_GAME_DONE_FAILURE:
			return {};

		default:
			return state
  }
}

export default quote