import React, {Component} from 'react';
import classNames from 'classnames';

import green from '@material-ui/core/colors/green';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
	message: {
		display: 'flex',
		alignItems: 'center',
	},
	success: {
	    backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing.unit,
	},
});

class Alert extends Component {    	
	
	render() {
		const { open, handleClose, classes, type, message} = this.props

		let snackIcon = ""
		if( type === "success" ){
			snackIcon = <CheckCircleIcon className={classNames(classes.icon, classes.iconVariant)} />
		}else{
			snackIcon = <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} />
		}

		return <Snackbar
          		anchorOrigin={{
            		vertical: 'top',
            		horizontal: 'center',
          		}}
          		open={open}
          		autoHideDuration={6000}
          		onClose={handleClose}
          		ContentProps={{
            		'aria-describedby': 'message-id',
          		}}
          		action={[
		            <IconButton
		              key="close"
		              aria-label="Close"
		              color="inherit"
		              className={classes.close}
		              onClick={handleClose}
		            >
		              <CloseIcon />
		            </IconButton>,
          		]}>
      		<SnackbarContent
  				className={classNames(classes[type])}
  				aria-describedby="client-snackbar"
  				message={ <span id="client-snackbar" className={classes.message}>
      					{snackIcon}
      					{message}
    				</span>
  				}
  				action={[
			        <IconButton
			          key="close"
			          aria-label="Close"
			          color="inherit"
			          className={classes.close}
			          onClick={handleClose}
			        >
			          <CloseIcon className={classes.icon} />
			        </IconButton>,
  				]} />
      	</Snackbar>	
	}
}

export default withStyles(styles)(Alert);