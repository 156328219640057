import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';

import QuoteListContainer from '../../../quote/quoteListContainer/QuoteListContainer'

const styles = theme => ({
	section:{
		marginBottom: theme.spacing.unit * 8,
	},
	title:{
		marginBottom: theme.spacing.unit * 4,
	},
	titleH1:{
		color: theme.palette.primary.text
	}
})

function Main(props){    	
	const { classes, quoteList, topic , onQuoteLoad} = props
	return <div>
		<div className={classes.title}>
			<Typography className={classes.titleH1} component="h1" variant="h4" gutterBottom >{topic.title}</Typography>
			<Typography variant="h6">{topic.description_short}</Typography>			
		</div>
		<QuoteListContainer quotes={quoteList} page="quoteTopic" onQuoteLoad={onQuoteLoad} />
	</div>
}

export default withStyles(styles)(Main);