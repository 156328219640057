import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import StickyBox from "react-sticky-box";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import MenuContainer from './menuContainer/MenuContainer';

const styles = theme => ({
	container: {
		display: "flex", 
		alignItems: "flex-start",
	},	
	colContainer: {
  		padding: theme.spacing.unit * 4,
	},
	left: {  		
  		backgroundColor: theme.palette.background.white,
  		//boxShadow:  theme.shadows[3],
  		/*'& $colContainer': {
  			paddingLeft: theme.spacing.unit  * 2,
  		}*/
  	},
  	main: {
  		backgroundColor: theme.palette.primary.light,  		
  		boxShadow:  theme.shadows[3],
  		height: '100%',
  	},
  	right: {
  		backgroundColor: theme.palette.background.white,
  		//boxShadow: theme.shadows[1],
  		/*'& $colContainer': {
  			paddingRight: theme.spacing.unit  * 2,
  		}*/
  	},  	
});

const Page3Col = (props) => {
	const {classes} = props
	return <div>
		<MenuContainer />
		<Grid container className={classes.container}>		
			
			<Hidden smDown>
				<Grid item xs={12} sm={3} className={classes.left}>
					<div className={classes.colContainer}>
						{props.left}
					</div>
				</Grid>
				<Grid component={StickyBox} item xs={12} sm={6} className={classes.main}>
					<div className={classes.colContainer}>
						{props.main}			
					</div>
				</Grid>
				<Grid  item xs={12} sm={3} className={classes.right}>
					<div className={classes.colContainer}>
						{props.right}
					</div>
				</Grid>
			</Hidden>
			<Hidden mdUp>
				<Grid item xs={12} sm={12} className={classes.main}>
					<div className={classes.colContainer}>
						{props.main}			
					</div>
				</Grid>
				<Grid item xs={12} sm={12} className={classes.left}>
					<div className={classes.colContainer}>
						{props.left}
					</div>
				</Grid>
				<Grid  item xs={12} sm={12} className={classes.right}>
					<div className={classes.colContainer}>
						{props.right}
					</div>
				</Grid>
			</Hidden>


			
		</Grid>
	</div>    
}

export default withStyles(styles)(Page3Col)